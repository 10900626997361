import { z } from 'zod';

export const statusFieldSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  color: z.string(),
});

export const workflowFieldSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  createdAt: z.string(),
  updatedBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  updatedAt: z.string(),
  statuses: z.union([z.record(statusFieldSchema), z.array(z.any())]),
});

export const workflowListSchema = z.object({
  workflows: z.array(workflowFieldSchema),
  pagination: z.object({
    count: z.number(),
    limit: z.number(),
    offset: z.number(),
  }),
});

export const workflowSchema = z.array(workflowFieldSchema);
export type Workflow = z.infer<typeof workflowFieldSchema>;

export type WorkflowStatuses = {
  id: string;
  name: string;
  description: string;
  color: string;
};
