import { create } from 'zustand';

type Asset = {
  metadata: Record<string, any>;
  workflows: Record<string, string>;
  tags: Array<string>;
};

type StdNode = {
  id: string;
  name: string;
  slug: string;
};

type TreeNode = StdNode & {
  parent: FolderNode;
};

type FolderNode = TreeNode & {
  type: 'folder';
  children: Array<DAMNode>;
};

type AlbumNode = TreeNode & {
  type: 'album';
  assets: Array<Asset>;
};

type AssetNode = TreeNode & {
  type: 'asset';
  asset: Asset;
};

type DAMNode = FolderNode | AlbumNode | AssetNode;

type NodePath = string[];

interface TreeStore {
  selectedAlbum: string | undefined;
  setSelectedAlbum: (string: string | undefined) => void;
  selectedFolder: string | undefined;
  setSelectedFolder: (string: string | undefined) => void;
  rootFolders: Array<FolderNode>;
  setRootFolders: (folders: FolderNode[]) => void;
  updateNodeChildren: (nodeId: string, children: DAMNode[]) => void;
  findNodePath: (nodeId: string) => NodePath | null;
  findParentNode: (nodeId: string) => DAMNode | null;
}

export const convertToDAMNode = (
  apiNode: any,
  updateNodeChildren: (nodeId: string, children: DAMNode[]) => void,
): DAMNode => {
  const { id, type, node, children = [] } = apiNode;

  if (type === 'folder') {
    // Convert children recursively
    const convertedChildren = children.map((child: any) => convertToDAMNode(child, updateNodeChildren));

    // Update the tree state for the folder node
    updateNodeChildren(node.id, convertedChildren);

    return {
      id: node.id,
      name: node.name,
      slug: node.slug,
      type: 'folder',
      parent: null as unknown as FolderNode, // Update based on the parent context
      children: convertedChildren,
    };
  }

  if (type === 'album') {
    return {
      id: node.id,
      name: node.name,
      slug: node.slug,
      type: 'album',
      parent: null as unknown as FolderNode, // Update based on the parent context
      assets: children.map((child: any) => ({
        metadata: child.metadata || {},
        workflows: child.workflows || {},
        tags: child.tags || [],
      })),
    };
  }

  if (type === 'asset') {
    return {
      id: node.id,
      name: node.name,
      slug: node.slug,
      type: 'asset',
      parent: null as unknown as FolderNode, // Update based on the parent context
      asset: {
        metadata: node.metadata || {},
        workflows: node.workflows || {},
        tags: node.tags || [],
      },
    };
  }

  throw new Error(`Unknown node type: ${type}`);
};

export const convertToFolderNode = (
  apiData: Array<{
    id: string;
    type: string;
    node: {
      id: string;
      slug: string;
      name: string;
      locked?: boolean;
      created_at?: string;
      updated_at?: string;
    };
    with_children: boolean;
  }>,
): FolderNode[] => {
  return apiData.map((item) => ({
    id: item.node.id,
    name: item.node.name,
    slug: item.node.slug,
    type: 'folder',
    parent: null as unknown as FolderNode, // Root folders have no parent
    children: [], // Initialize empty; load children separately if needed
  }));
};

export const useTreeStore = create<TreeStore>((set, get) => ({
  // Initial state with root folders
  selectedAlbum: undefined,
  setSelectedAlbum: (selectedAlbum) => set({ selectedAlbum }),
  selectedFolder: undefined,
  setSelectedFolder: (selectedFolder) => set({ selectedFolder }),
  rootFolders: [],
  setRootFolders: (folders) => set({ rootFolders: folders }),

  // Update the children of a node
  updateNodeChildren: (nodeId, children) => {
    const updateChildrenRecursively = (folders: FolderNode[]): FolderNode[] => {
      return folders.map((folder) => {
        if (folder.id === nodeId) {
          // Update the children of the matching node
          return { ...folder, children };
        }

        // Only recurse if there are children
        if (folder.children?.length) {
          return {
            ...folder,
            children: updateChildrenRecursively(folder.children as FolderNode[]),
          };
        }

        return folder; // Return folder as is if no children
      });
    };

    set((state) => ({
      rootFolders: updateChildrenRecursively(state.rootFolders),
    }));
  },

  // Find the path to a specific node by its ID
  findNodePath: (nodeId) => {
    const findPath = (nodes: DAMNode[], path: NodePath): NodePath | null => {
      for (const node of nodes) {
        if (node.id === nodeId) return [...path, node.slug];

        if (node.type === 'folder') {
          const result = findPath(node.children, [...path, node.slug]);
          if (result) return result;
        }
      }
      return null;
    };
    return findPath(get().rootFolders, []);
  },

  findParentNode: (nodeId: string) => {
    const findParent = (nodes: DAMNode[], parent: DAMNode): DAMNode | null => {
      for (const node of nodes) {
        if (node.id === nodeId) return parent;

        if (node.type === 'folder') {
          const result = findParent(node.children, node);
          if (result) return result;
        }
      }
      return null;
    };
    return findParent(get().rootFolders, null as unknown as DAMNode);
  },
}));
