import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { deleteWorkflowStatus } from '@/services/workflow.service';
import { error as errorLog } from '@/utilities/log';
import { useModalData } from '@/context/ModalContext';
import { ToastAction } from '@/components/ui/toast';
import { useToast } from '@/components/ui/use-toast';
import { Modal } from '@/components/modal/modal';

export const DeleteWorkflowStatusAlertDialog = () => {
  const { toast } = useToast();

  const modalData = useModalData()[0];
  const queryClient = useQueryClient();

  const deleteWorkflowStatusWithAuth = useAuthenticatedQueryFn(deleteWorkflowStatus);

  const deleteWorkflowStatusMutation = useMutation({
    mutationFn: async () => {
      await deleteWorkflowStatusWithAuth({
        workflowId: modalData.workflowId,
        statusId: modalData.statusId,
      });
    },
    onSuccess: () => {
      toast({
        title: 'Workflow status deleted',
        description: `Workflow status has been deleted successfully.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['workflowList'] });
      void queryClient.invalidateQueries({ queryKey: ['workflow', modalData.workflowId] });
    },
    onError: (err) => {
      errorLog('Action failed:', err);

      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  return (
    <Modal id="deleteWorkflowStatusAlertDialog" variant="alert">
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription className="flex items-center">
            {`Are you sure you want to delete workflow status `}
            <div className="ml-2 mr-1 size-3 rounded-full" style={{ backgroundColor: modalData?.color }} />
            <span className="font-bold">{modalData?.name}</span>
            {`?`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="!bg-red-500 !text-white transition-all duration-150 hover:!bg-red-600"
            onClick={() => {
              if (modalData.workflowId && modalData.statusId) {
                deleteWorkflowStatusMutation.mutate();
              }
            }}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </Modal>
  );
};
