import { FC } from 'react';
import {
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
} from '@/components/ui/alert-dialog';
import { DeleteNodeAlertAction } from '@/components/delete-dialogs/delete-node-alert-action';

export const DeleteNodeAlertDialog: FC<{
  open: boolean;
  setOpenDialogId: any;
  message: string;
  selectedList: Array<{ id: string; name: string }>;
  isRemove: boolean;
  removeAction: () => void;
  deleteAction: () => void;
}> = ({ open, setOpenDialogId, message, selectedList, isRemove, removeAction, deleteAction }) => {
  return (
    <AlertDialog open={open} onOpenChange={() => setOpenDialogId(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            {message}:
            <ul className="py-2">{selectedList?.slice(0, 5).map(({ id, name }) => <li key={id}>{name}</li>)}</ul>
            {selectedList?.length > 5 && <p>and {selectedList.length - 5} more...</p>}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <DeleteNodeAlertAction removeAction={removeAction} deleteAction={deleteAction} isRemove={isRemove} />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
