import { FC, forwardRef, useMemo } from 'react';
import { cn } from '@/lib/utils';
import { Option } from '@/types/select-option';
import { DropdownIndicator, ClearIndicator } from '@/components/ui/react-select-elements';
import Select, { MenuPlacement, OnChangeValue } from 'react-select';
import {
  controlStyles,
  placeholderStyles,
  selectInputStyles,
  valueContainerStyles,
  singleValueStyles,
  multiValueStyles,
  multiValueLabelStyles,
  multiValueRemoveStyles,
  indicatorsContainerStyles,
  clearIndicatorStyles,
  indicatorSeparatorStyles,
  dropdownIndicatorStyles,
  menuStyles,
  groupHeadingStyles,
  optionStyles,
  noOptionsMessageStyles,
} from '@/components/ui/react-select-styles';

interface MultiSelectProps {
  value: Array<string>;
  options: Array<Option>;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onChange: (newValue: OnChangeValue<Option, true>) => void;
  menuPlacement?: MenuPlacement;
  menuListStyle?: string;
}

export const MultiSelect: FC<MultiSelectProps> = forwardRef<HTMLDivElement, MultiSelectProps>(
  ({
    value,
    options,
    className,
    placeholder = 'Select...',
    isClearable = true,
    isSearchable = true,
    disabled = false,
    isLoading = false,
    onChange,
    menuPlacement = 'auto',
    menuListStyle = '',
  }) => {
    const selectedOptions = useMemo(() => {
      const arrayValue = Array.isArray(value) ? value : [];

      return arrayValue
        ?.map((val) => options.find((option) => option.value === val))
        .filter((option) => option !== undefined) as Array<Option>;
    }, [value, options]);

    return (
      <Select
        menuPlacement={menuPlacement}
        isMulti
        value={selectedOptions}
        onChange={onChange}
        options={options}
        className={className}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={disabled}
        components={{
          DropdownIndicator,
          ClearIndicator,
        }}
        isLoading={isLoading}
        noOptionsMessage={() => 'No options found'}
        classNames={{
          control: ({ isFocused, isDisabled }) =>
            cn(
              isDisabled && controlStyles.disabled,
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base,
            ),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected, isDisabled }) =>
            cn(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              isDisabled && optionStyles.disabled,
              optionStyles.base,
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
          menuList: () => menuListStyle,
        }}
      />
    );
  },
);

MultiSelect.displayName = 'MetadataMultiselect';
