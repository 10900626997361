import { useMemo, useState } from 'react';
import { DialogContent, Dialog, DialogHeader, DialogTitle, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { Form, FormLabel } from '@/components/ui/form';
import { fieldTypeSchema } from '@/types/metadata';
import { createMetadata } from '@/services/metadata.service';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { typeOptions } from '@/pages/manage/metadata';
import { ScrollArea } from '@/components/ui/scroll-area';
import { OptionItem } from '@/components/inspector/entity-fields/metadata-fields';
import { useDialog } from '@/context/DialogContext';
import { Separator } from '@/components/ui/separator';
import { kebabCase } from 'lodash';
import { useToast } from '@/components/ui/use-toast';
import { Options } from '@/components/inspector/entity-fields/form/options';
import { useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ulid } from 'ulid';
import { log } from '@/utilities/log';
import { z } from 'zod';

export const CreateMetadataDialog = () => {
  const [options, setOptions] = useState<Record<string, { name: string }>>({});
  const [editMode, setEditMode] = useState<string | null>(null); // Track the option being edited

  const { closeModal, openDialogId, setOpenDialogId } = useDialog();

  const formFields: Array<any> = useMemo(
    () => [
      {
        name: 'Name',
        slug: 'name',
        type: 'string',
        value: '',
        description: 'Name of the metadata field',
        validation: z.string().min(1, { message: 'Name field is required.' }),
      },
      {
        name: 'Description',
        slug: 'description',
        type: 'string',
        value: '',
        description: 'Description under the field or in a tooltip on smaller resolutions',
        validation: z.string().optional(),
      },
      {
        name: 'Type',
        slug: 'type',
        type: 'select',
        value: '',
        options: typeOptions,
        description: 'Type of the metadata field',
        validation: fieldTypeSchema.refine((val) => val !== undefined, {
          message: 'Type field is required.',
        }),
      },
      {
        name: 'Options',
        slug: 'type-options',
        type: 'type-options',
        value: { choices: {} },
        description: 'Options of the metadata field',
        validation: z
          .object({
            choices: z.record(z.string()).optional(),
          })
          .optional(),
      },
      {
        name: 'Searchable',
        slug: 'searchable',
        type: 'boolean',
        value: false,
        description: 'Whether assets with this tag applied to will appears in search results including the tag name.',
        validation: z.boolean(),
      },
      {
        name: 'Facet',
        slug: 'facet',
        type: 'boolean',
        value: false,
        description: 'Whether the tag is considered a facet. It must have children in order to appear as a facet.',
        validation: z.boolean(),
      },
      {
        name: 'Public',
        slug: 'public',
        type: 'boolean',
        value: false,
        description: 'Whether the tag can be shown publicly when publishing the asset externally.',
        validation: z.boolean(),
      },
      {
        name: 'Position',
        slug: 'position',
        type: 'float',
        value: 0,
        description: 'Position of the field in the list',
        validation: z.coerce.number().min(0, { message: 'Position must be a positive number.' }),
        min: 0,
      },
    ],
    [],
  );

  const formSchema = z.object(
    formFields.reduce((acc, field) => {
      acc[field.slug] = field.validation;
      return acc;
    }, {}),
  );

  const defaultValues = formFields.reduce(
    (acc, field) => {
      acc[field.slug] = field.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  const { toast } = useToast();
  const { watch } = form;

  const fieldType = watch('type');

  const handleRemoveOption = (key: string) => {
    setOptions((prev) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleAddNewOption = () => {
    const newOptionKey = ulid();
    setOptions((prev) => ({ ...prev, [newOptionKey]: { name: '' } }));
    setEditMode(newOptionKey);
  };

  const resetForm = () => {
    form.reset(defaultValues);
    setOptions(defaultValues['type-options']?.choices || {});
  };

  const createMetadataWithAuth = useAuthenticatedQueryFn(createMetadata);
  const queryClient = useQueryClient();

  const createMetadataMutation = useMutation({
    mutationFn: createMetadataWithAuth,
    onSuccess: () => {
      toast({
        title: 'Metadata field created',
        description: `Metadata field has been created successfully.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['metadataList'] });

      closeModal();
      resetForm();
    },
    onError: (err: BackendError) => {
      handleError(err);
      log(err);
    },
  });

  const isLoading = createMetadataMutation.status === 'pending';

  function onSubmit(values: z.infer<typeof formSchema>) {
    const cleanedOptions = Object.fromEntries(
      Object.entries(options).filter(([_, option]) => option.name && option.name.trim() !== ''),
    );

    const keys = Object.values(cleanedOptions).map((option) => kebabCase(option.name));
    const duplicates = keys.filter((key, index) => keys.indexOf(key) !== index);
    if (duplicates.length > 0) {
      toast({
        title: 'Duplicate Options Found',
        description: 'Each option must be unique. Please ensure there are no duplicate entries.',
      });
      return;
    }

    const formattedOptions = Object.keys(cleanedOptions).length
      ? {
          choices: Object.fromEntries(
            Object.entries(cleanedOptions).map(([key, option]) => [kebabCase(option.name), option.name]),
          ),
        }
      : {};

    createMetadataMutation.mutate({
      ...values,
      options: formattedOptions,
    });
  }

  return (
    <Dialog
      open={openDialogId === 'createMetadata'}
      onOpenChange={() => {
        setOpenDialogId(null);
        resetForm();
      }}
    >
      <DialogContent onPointerDownOutside={(e) => e.preventDefault()} className="p-0">
        <DialogHeader className="p-6">
          <DialogTitle>Create Field</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <ScrollArea className="max-h-[calc(100vh-15rem)] overflow-y-auto">
              <div className="space-y-4 px-6">
                {formFields.map((field) =>
                  // Render the 'type' field with options if it's 'select' or 'multi_select'
                  field.slug === 'type' ? (
                    <div key={field.slug}>
                      {renderField(form, field, 'hidden')}

                      {/* Render options immediately after type field if type is 'select' or 'multi_select' */}
                      {(fieldType === 'select' || fieldType === 'multi_select') && (
                        <div className="mt-4">
                          <FormLabel
                            htmlFor={field.slug}
                            className="mx-2 leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:mx-0 @[30rem]/inspector:text-right"
                          >
                            Options
                          </FormLabel>
                          <div className="mt-2 flex flex-col gap-2">
                            <Options handleAddNewOption={handleAddNewOption} disabled={isLoading}>
                              {Object.keys(options).map((optionKey) => (
                                <OptionItem
                                  key={optionKey}
                                  optionKey={optionKey}
                                  options={options}
                                  editMode={editMode}
                                  setOptions={setOptions}
                                  handleRemoveOption={handleRemoveOption}
                                  setEditMode={setEditMode}
                                />
                              ))}
                            </Options>
                            <Separator className="mt-2 block" />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    renderField(form, field, 'hidden')
                  ),
                )}
              </div>
            </ScrollArea>

            <DialogFooter className="p-6">
              <DialogClose asChild className="mt-2 sm:mt-0">
                <Button type="button" variant="secondary" disabled={isLoading} data-cy="create-metadata-dismiss-button">
                  Dismiss
                </Button>
              </DialogClose>
              <Button
                disabled={isLoading}
                type="submit"
                variant={'default'}
                className="justify-center"
                data-cy="create-metadata-create-button"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 size-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  'Create'
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
