import { ChangeEvent, FC } from 'react';
import { ListFilter } from 'lucide-react';
import { Input } from '@/components/ui/input';

export const FilterBar: FC<{
  queryString?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  showFilterInput: boolean;
}> = ({ queryString, onChange, showFilterInput }) => {
  if (!showFilterInput) {
    return null;
  }

  return (
    <div className="flex h-8 items-center gap-x-2 bg-void-gap px-2">
      <ListFilter data-cy="gallery-search-filter-icon" className="size-5 min-h-5 min-w-5 stroke-gray-icon-color" />
      <div className="text-sm font-bold">Filter:</div>
      <Input
        type="search"
        defaultValue={queryString}
        placeholder="Type here to filter results in this view"
        onChange={onChange}
        className="z-20 max-h-full min-h-full w-full rounded-none border-0 bg-transparent p-0 shadow-none focus-within:outline-none focus-within:ring-0 focus-within:ring-offset-0 focus-within:duration-0"
      />
    </div>
  );
};
