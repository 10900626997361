import { z } from 'zod';
import { paginationSchema } from '@/types/pagination';
import { assetSchema } from '@/types/asset';
import { previewVariantsFieldSchema } from '@/types/variant';

const shareAlbumSchema = z.object({
  id: z.string(),
  nodeId: z.string(),
  node: z.object({
    id: z.string(),
    name: z.string(),
    slug: z.string(),
    type: z.string(),
    album: z.object({
      createdAt: z.string(),
      updatedAt: z.string(),
      description: z.string(),
      assets: z.object({
        assets: z.array(assetSchema),
        pagination: paginationSchema,
      }),
    }),
  }),
});

export const shareFieldSchema = z.object({
  id: z.string(),
  nodeId: z.string(),
  expiresAt: z.string().nullable(),
  sharedWith: z.string().nullable(),
  extraVariants: z.array(previewVariantsFieldSchema).nullable().optional(),
  previewVariant: previewVariantsFieldSchema.nullable().optional(),
});

export const shareListSchema = z.object({
  shares: z.array(shareFieldSchema),
  pagination: paginationSchema,
});

const CreateShareParams = z.object({
  nodeId: z.string(),
  body: z.object({
    sharedWith: z.array(z.string().email()).optional().nullable(),
    expiresAt: z.string().optional().nullable(),
    variants: z
      .object({
        preview: z.string().optional().nullable(),
        extra: z.array(z.string()).optional().nullable(),
      })
      .optional()
      .nullable(),
  }),
});

const UpdateShareParams = z.object({
  nodeId: z.string(),
  shareId: z.string(),
  body: z.object({
    sharedWith: z.array(z.string().email()).optional().nullable(),
    expiresAt: z.string().optional().nullable(),
    variants: z
      .object({
        preview: z.string().optional().nullable(),
        extra: z.array(z.string()).optional().nullable(),
      })
      .optional()
      .nullable(),
  }),
});

export type CreateShareParams = z.infer<typeof CreateShareParams>;
export type UpdateShareParams = z.infer<typeof UpdateShareParams>;
export const shareSchema = shareFieldSchema;
export type Share = z.infer<typeof shareFieldSchema>;
export type AlbumShare = z.infer<typeof shareAlbumSchema>;

export const shareFormSchema = z.object({
  sharedWith: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    )
    .superRefine((data, ctx) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const invalid = data?.find((item) => {
        return !emailRegex.test(item.label) || !emailRegex.test(item.value);
      });

      if (invalid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'One or more emails in your list have an invalid format',
          path: [],
        });
      }
    })
    .nullable()
    .optional(),
  expiresAt: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, { message: 'Invalid date format. Expected format is YYYY-MM-DD.' })
    .nullable()
    .optional(),
  variants: z.object({
    preview: z.string().nullable().optional(),
    extra: z.array(z.string()).nullable().optional(),
  }),
});
