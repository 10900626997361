import { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';

export const useSearchQuery = () => {
  const router = useRouter();
  const { query } = router;
  const [queryString, setQueryString] = useState<string>((query.queryString as string) ?? '');
  const [showFilterInput, setShowFilterInput] = useState<boolean>(true);

  const handleSearchOnChange = useCallback((queryString: string) => {
    setQueryString(queryString);
  }, []);

  const debouncedSearch = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      handleSearchOnChange(e.target.value);
    }, 300),
    [handleSearchOnChange],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return { queryString, debouncedSearch, showFilterInput, setShowFilterInput };
};
