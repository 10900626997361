import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import {
  albumSchema,
  RenameAlbumParams,
  addAssetsResponseSchema,
  removeAssetsResponseSchema,
  CreateAlbumParams,
  DeleteAlbumParams,
  reorderAssetsSchema,
} from '@/types/album';
import { getAuditsSchema } from '@/types/audit';

const getAlbum = async (params?: string, token?: string) => {
  const response = await damRequest('GET', `${INTERNAL_V1}/albums/${params}`, token, {});
  return albumSchema.parse(response);
};

const renameAlbum = async (
  { id, name, description, slug }: RenameAlbumParams & { id?: string | null },
  token?: string,
) => {
  const response = await damRequest('PATCH', `${INTERNAL_V1}/albums/${id}`, token, {
    body: { name, description, slug },
  });

  return albumSchema.parse(response);
};

const createAlbum = async (params?: CreateAlbumParams, token?: string) => {
  const response = await damRequest('POST', `${INTERNAL_V1}/albums`, token, {
    body: params,
  });

  return albumSchema.parse(response);
};

const addAlbumAssets = async (params?: { albumId: string; assetIds: Array<string> }, token?: string) => {
  const body = {
    add: params?.assetIds,
  };

  const response = await damRequest('PATCH', `${INTERNAL_V1}/albums/${params?.albumId}/assets`, token, {
    body,
  });
  return addAssetsResponseSchema.parse(response);
};

const removeAlbumAssets = async (params?: { albumId: string; assetIds: Array<string> }, token?: string) => {
  const body = {
    remove: params?.assetIds,
  };

  const response = await damRequest('PATCH', `${INTERNAL_V1}/albums/${params?.albumId}/assets`, token, {
    body,
  });
  return removeAssetsResponseSchema.parse(response);
};

const deleteAlbum = async (params?: DeleteAlbumParams, token?: string) => {
  const response = await damRequest('DELETE', `${INTERNAL_V1}/albums/${params?.id}`, token);
  return albumSchema.parse(response);
};

const unDeleteAlbum = async (params?: DeleteAlbumParams, token?: string) => {
  const response = await damRequest('POST', `${INTERNAL_V1}/albums/${params?.id}/undelete`, token, {});
  return albumSchema.parse(response);
};

const getAlbumAuditLog = async (
  params?: { id?: string; pagination?: { offset: number; limit: number } },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? 20),
  }).toString();

  const response = await damRequest('GET', `${INTERNAL_V1}/albums/${params?.id}/audit?${query}`, token);
  return getAuditsSchema.parse(response);
};

const reorderAlbumAssets = async (
  params?: { albumId: string; assetIds: Array<string>; position: number },
  token?: string,
) => {
  const body = {
    order: {
      position: params?.position,
      assets: params?.assetIds,
    },
  };

  const response = await damRequest('PATCH', `${INTERNAL_V1}/albums/${params?.albumId}/assets`, token, {
    body,
  });

  return reorderAssetsSchema.parse(response);
};

export {
  getAlbum,
  renameAlbum,
  addAlbumAssets,
  removeAlbumAssets,
  createAlbum,
  deleteAlbum,
  unDeleteAlbum,
  getAlbumAuditLog,
  reorderAlbumAssets,
};
