import { useEffect, useMemo, useState } from 'react';
import { useModalSequence, useCloseModal, useModalBack, useModalData } from '@/context/ModalContext';
import { DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { createWorkflowStatus, updateWorkflowStatus } from '@/services/workflow.service';
import { error as errorLog } from '@/utilities/log';
import { Form, FormLabel } from '@/components/ui/form';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { ColorPicker } from '@/components/ui/color-picker';
import { zodResolver } from '@hookform/resolvers/zod';
import { useToast } from '@/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Modal } from '@/components/modal/modal';
import { Input } from '@/components/ui/input';
import { ulid } from 'ulid';
import { z } from 'zod';

export const CreateWorkflowStatusDialog = ({
  modalId,
  statuses,
  setStatuses,
}: {
  modalId: string;
  statuses: any;
  setStatuses: any;
}) => {
  const [color, setColor] = useState('#1456ff');

  const { toast } = useToast();

  const goBack = useModalBack();
  const modalSequence = useModalSequence();
  const closeModal = useCloseModal();
  const modalData = useModalData()[0];

  const formFields: Array<any> = useMemo(
    () => [
      {
        name: 'Status Name',
        slug: 'name',
        type: 'string',
        value: '',
        validation: z.string().min(1, { message: 'Name field is required.' }),
      },
      {
        name: 'Status Description',
        slug: 'description',
        type: 'text',
        value: '',
        validation: z.string({ required_error: 'The description field must be a string.' }).min(1, {
          message: 'Description field is required.',
        }),
      },
      {
        name: 'Color',
        slug: 'color',
        type: 'color',
        value: '#1456ff',
        validation: z.string().optional(),
      },
    ],
    [],
  );

  const formSchema = z.object(
    formFields.reduce((acc, field) => {
      acc[field.slug] = field.validation;
      return acc;
    }, {}),
  );

  const defaultValues = formFields.reduce(
    (acc, field) => {
      acc[field.slug] = field.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  useEffect(() => {
    if (modalData) {
      form.reset({
        name: modalData.name || '',
        description: modalData.description || '',
        color: modalData.color,
      });
      setColor(modalData.color);
    }
  }, [modalData, form]);

  const queryClient = useQueryClient();
  const createWorkflowStatusWithAuth = useAuthenticatedQueryFn(createWorkflowStatus);

  const createWorkflowStatusMutation = useMutation({
    mutationFn: createWorkflowStatusWithAuth,
    onSuccess: () => {
      toast({
        title: 'Workflow status created',
        description: `Workflow status has been created successfully.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['workflowList'] });
      void queryClient.invalidateQueries({ queryKey: ['workflow', modalData.workflowId] });

      closeModal();
      form.reset();
    },
    onError: (err: BackendError) => {
      handleError(err);
      errorLog(err);
    },
  });

  const updateWorkflowStatusWithAuth = useAuthenticatedQueryFn(updateWorkflowStatus);

  const updateWorkflowStatusMutation = useMutation({
    mutationFn: updateWorkflowStatusWithAuth,
    onSuccess: () => {
      toast({
        title: 'Workflow status updated',
        description: `Workflow status has been updated successfully.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['workflowList'] });
      void queryClient.invalidateQueries({ queryKey: ['workflow', modalData.workflowId] });

      closeModal();
      form.reset();
    },
    onError: (err: BackendError) => {
      handleError(err);
      errorLog(err);
    },
  });

  const isLoading =
    createWorkflowStatusMutation.status === 'pending' || updateWorkflowStatusMutation.status === 'pending';
  // If modal sequence is more than 1, we are creating a status for a workflow, otherwise we are updating the status
  const isCreatingWorkflow = modalSequence.length > 1;
  const isCreatingWorkflowStatus = modalData?.action === 'createWorkflowStatus';
  const isUpdatingWorkflowStatus = modalData?.action === 'updateWorkflowStatus';

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (isCreatingWorkflow && isUpdatingWorkflowStatus) {
      // Editing workflow status when creating a new workflow
      const statusId = Object.keys(statuses).find((key) => statuses[key].name === modalData.name) as string;

      setStatuses((prev: any) => ({
        ...prev,
        [statusId]: { name: values.name, description: values.description, color: values.color },
      }));

      // Go back to previous modal
      goBack();
    } else if (isCreatingWorkflow && isCreatingWorkflowStatus) {
      // Creating status for a new workflow
      setStatuses((prev: any) => ({
        ...prev,
        [ulid()]: { name: values.name, description: values.description, color: values.color },
      }));

      // Go back to previous modal
      goBack();
      form.reset();
    } else if (isCreatingWorkflowStatus) {
      // Creating status for created workflow
      createWorkflowStatusMutation.mutate({
        workflowId: modalData.workflowId,
        body: values,
      });
    } else {
      // Updating status for created workflow
      updateWorkflowStatusMutation.mutate({
        workflowId: modalData.workflowId,
        statusId: modalData.statusId,
        body: values,
      });
    }
  }

  return (
    <Modal id={modalId} backOnClose>
      <DialogContent onPointerDownOutside={(e) => e.preventDefault()}>
        <DialogHeader className="pb-6">
          <DialogTitle>{isCreatingWorkflowStatus ? 'Create' : 'Update'} Workflow Status</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              {formFields.map((field) =>
                field.slug === 'color' ? (
                  <div key={field.slug}>
                    <div className="mt-4">
                      <FormLabel htmlFor={field.slug} className="mx-2 leading-4">
                        Color
                      </FormLabel>
                      <div className="mt-2 flex flex-row items-center gap-2">
                        <ColorPicker
                          onChange={(v) => {
                            setColor(v);
                            form.setValue('color', v);
                          }}
                          value={color}
                        />
                        <Input
                          id={field.slug}
                          name={field.slug}
                          type="text"
                          value={color}
                          className="bg-white focus-within:ring-offset-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950"
                          onChange={(e) => {
                            setColor(e.target.value);
                            form.setValue('color', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  renderField(form, field, 'hidden')
                ),
              )}
            </div>
            <DialogFooter className="pt-6">
              <Button
                type="button"
                variant="secondary"
                onClick={isCreatingWorkflow ? goBack : () => closeModal()}
                data-cy={`create-workflow-status-${isCreatingWorkflow ? 'dismiss' : 'back'}-button`}
                {...(!isCreatingWorkflow && { disabled: isLoading })}
              >
                {isCreatingWorkflow ? 'Back' : 'Dismiss'}
              </Button>
              <Button type="submit" disabled={isLoading} data-cy="create-workflow-status-save-button">
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Modal>
  );
};
