import { FC, useMemo, useState } from 'react';
import { useClearRefinements, useInstantSearch } from 'react-instantsearch';
import { FilterItem } from '@/components/sidebar/filter-item';
import { Button } from '@/components/ui/button';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { useSearch } from '@/hooks/search/useSearch';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { ChevronRightIcon, ChevronsDownUp, ChevronsUpDown, Eraser } from 'lucide-react';

export const SearchOptions: FC<{ className?: string }> = ({ className }) => {
  const [openStates, setOpenStates] = useState<Record<string, boolean>>({});
  const [allOpen, setAllOpen] = useState(false);

  const { indexUiState } = useInstantSearch();
  const { refine, canRefine } = useClearRefinements();
  const { isMobile } = useBreakpoint();
  const { searchFacets } = useSearch();

  const currentRefinementCount = useMemo(
    () => Object.values(indexUiState?.refinementList || {}).reduce((sum, list) => sum + list.length, 0),
    [indexUiState?.refinementList],
  );

  const toggleAll = () => {
    const newState = !allOpen;
    setAllOpen(newState);

    const newOpenStates = Object.keys(searchFacets).reduce(
      (acc, key) => {
        acc[key] = newState;
        return acc;
      },
      {} as Record<string, boolean>,
    );

    setOpenStates(newOpenStates);
  };

  const handleToggle = (facetKey: string) => {
    setOpenStates((prev) => ({
      ...prev,
      [facetKey]: !prev[facetKey],
    }));
  };

  return (
    <>
      <div
        className={cn(
          'relative flex w-full items-center justify-between bg-neutral-100 px-3 py-2 dark:bg-ul-color',
          isMobile ? 'from-white dark:from-black' : 'from-white dark:from-black',
        )}
      >
        <p className="font-semibold">Facets</p>
        <div className="relative flex">
          <Button
            disabled={!canRefine}
            className={cn(canRefine && 'bg-white py-2 hover:bg-white dark:bg-neutral-950 dark:hover:bg-neutral-950')}
            size="sm"
            variant="ghost"
            onClick={refine}
          >
            <Eraser className="size-6" />
            {currentRefinementCount !== 0 && (
              <Badge className="ml-2 rounded-full" variant="secondary">
                {currentRefinementCount}
              </Badge>
            )}
          </Button>
          <Button variant="ghost" onClick={toggleAll} className="p-2" size="sm" disabled={!allOpen}>
            <ChevronsDownUp className="size-6" />
          </Button>
          <Button variant="ghost" onClick={toggleAll} className="p-2" size="sm" disabled={allOpen}>
            <ChevronsUpDown className="size-6" />
          </Button>
        </div>
      </div>
      <div className="relative h-[calc(100vh-48px)] overflow-x-hidden overflow-y-scroll">
        <div className={cn('mt-px flex flex-col', className)}>
          {(Object.entries(searchFacets) as Array<[string, { label: string; options?: Array<any> }]>).map(
            ([facetKey, facet]) => (
              <Collapsible
                key={facetKey}
                open={openStates[facetKey] ?? false}
                onOpenChange={() => handleToggle(facetKey)}
              >
                <CollapsibleTrigger className="flex w-full items-center gap-1.5 bg-neutral-200 p-1.5 text-left dark:bg-ui-bevel">
                  <ChevronRightIcon
                    className={cn('size-5.5 text-[#999999] transition duration-300', {
                      'rotate-90': openStates[facetKey],
                    })}
                  />
                  <p className="font-light">{facet?.label}</p>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  {facet.options &&
                    Object.entries(facet.options).map(([optionKey, optionValue]) => (
                      <FilterItem
                        key={`sidebar-${facetKey}-${optionKey}`}
                        filter={{
                          attribute: facetKey === 'custom' ? optionKey : `${facetKey}.${optionKey}`,
                          label: optionValue.name || optionValue,
                          options: optionValue.options || [],
                        }}
                      />
                    ))}
                </CollapsibleContent>
              </Collapsible>
            ),
          )}
        </div>
      </div>
    </>
  );
};
