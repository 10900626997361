import { useEffect, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { TenantUserRoleMap, tenantUserRoleMap, tenantUserRoleOptions } from '@/types/tenantUserRole';
import { cn } from '@/lib/utils';
import { RowEntity } from '@/context/DataTableSelectionContext';
import { Invitation } from '@/types/invitations';
import { format } from 'date-fns';

type InvitationFieldsProps = {
  invitation: RowEntity;
};

export const InvitationFields = ({ invitation }: InvitationFieldsProps) => {
  const { inviter, invitee, roles, createdAt, expiresAt } = invitation.original as Invitation;
  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>(['Invitation']);

  const role = roles[0];

  const formSchema = z.object({
    inviter: z.string(),
    invitee: z.string(),
    role: z.string(),
    createdAt: z.string(),
    expiresAt: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      invitee: '',
      inviter: '',
      role: '',
      createdAt: '',
      expiresAt: '',
    },
  });

  useEffect(() => {
    form.reset({
      invitee: invitee ?? '',
      inviter: inviter,
      role: role,
      createdAt: format(createdAt, 'LLL dd, y'),
      expiresAt: format(expiresAt, 'LLL dd, y'),
    });
  }, [form, invitee, inviter, role, createdAt, expiresAt]);

  const { setValue } = form;

  function onSubmit() {
    // Don't do anything
  }

  return (
    <Accordion
      onValueChange={(accordion) => {
        setCurrentAccordionItems(accordion);
      }}
      value={currentAccordionItems}
      type="multiple"
      className="w-full"
      defaultValue={['Profile']}
    >
      <AccordionItem className="border border-white dark:border-black " value="Invitation">
        <AccordionTrigger className="bg-white p-3 @[18rem]/inspector:py-3 dark:bg-neutral-800">
          Invitation
        </AccordionTrigger>
        <AccordionContent className="m-1 flex flex-col p-3">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="invitee"
                disabled={true}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className={cn(
                            'focus-visible:ring-offset-white dark:focus-visible:ring-offset-neutral-950',
                            form.formState.errors.invitee && '!border-red-500 dark:focus-visible:ring-red-500 ',
                          )}
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="role"
                disabled={true}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Role</FormLabel>
                    <FormControl>
                      <Select onValueChange={(value) => setValue('role', value)} disabled={true}>
                        <SelectTrigger className="focus:duration-0">
                          <SelectValue placeholder={tenantUserRoleMap[field.value as keyof TenantUserRoleMap]} />
                        </SelectTrigger>
                        <SelectContent>
                          {tenantUserRoleOptions.map(({ label, value }) => (
                            <SelectItem key={value} value={value}>
                              <div>{label}</div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="inviter"
                disabled={true}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Invited By</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className={cn(
                            'focus-visible:ring-offset-white dark:focus-visible:ring-offset-neutral-950',
                            form.formState.errors.invitee && '!border-red-500 dark:focus-visible:ring-red-500 ',
                          )}
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="createdAt"
                disabled={true}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Invited On</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className={cn(
                            'focus-visible:ring-offset-white dark:focus-visible:ring-offset-neutral-950',
                            form.formState.errors.invitee && '!border-red-500 dark:focus-visible:ring-red-500 ',
                          )}
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="expiresAt"
                disabled={true}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Expires On</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className={cn(
                            'focus-visible:ring-offset-white dark:focus-visible:ring-offset-neutral-950',
                            form.formState.errors.invitee && '!border-red-500 dark:focus-visible:ring-red-500 ',
                          )}
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
