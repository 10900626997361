import { ComponentPropsWithoutRef, FC } from 'react';
import { ListFilter } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export const ToolbarFilterButton: FC<ComponentPropsWithoutRef<'button'> & { showFilterInput?: boolean }> = ({
  showFilterInput,
  ...props
}) => (
  <Button variant="toolbar" size="toolbar" className="m-2 self-center" data-cy="toolbar-filter-button" {...props}>
    <ListFilter
      className={cn(
        'size-6 min-h-6 min-w-6 duration-150',
        showFilterInput
          ? 'stroke-highlight-color hover:stroke-highlight-hover-color'
          : 'stroke-gray-icon-color hover:stroke-gray-icon-hover-color',
      )}
      strokeWidth={2}
    />
  </Button>
);
