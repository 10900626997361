import { useEffect, useState, FC } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { GripHorizontal, Loader2, Pencil, Save, Trash } from 'lucide-react';
import { BackendError, useFormErrorHandler } from '@/hooks/useFormErrorHandler';
import { DeleteWorkflowStatusAlertDialog } from '@/components/delete-dialogs/delete-workflow-status-alert-dialog';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { CreateWorkflowStatusDialog } from '@/components/create-dialogs/create-workflow-status-dialog';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { FormProvider, useForm } from 'react-hook-form';
import { kebabCase, capitalize } from 'lodash';
import { error as errorLog } from '@/utilities/log';
import { updateWorkflow } from '@/services/workflow.service';
import { OptionAction } from '@/components/inspector/entity-fields/form/option-action';
import { useOpenModal } from '@/context/ModalContext';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { useWorkflow } from '@/hooks/workflow/useWorkflow';
import { zodResolver } from '@hookform/resolvers/zod';
import { Separator } from '@/components/ui/separator';
import { FormLabel } from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Options } from '@/components/inspector/entity-fields/form/options';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { z } from 'zod';

export interface OptionType {
  id: string;
  name: string;
  description?: string;
  color: string;
}

type OptionItemProps = {
  options: Record<string, OptionType>;
  optionKey: string;
  handleRemoveOption: (optionKey: string) => void;
  workflowId?: string;
};

export const OptionItem: FC<OptionItemProps> = ({ options, optionKey, handleRemoveOption, workflowId }) => {
  const openModal = useOpenModal();

  return (
    <div key={optionKey} className="flex h-12 flex-col justify-center gap-2 px-3">
      <div className="flex w-full items-center gap-2">
        <div className="flex grow items-center gap-2">
          <GripHorizontal className="size-5 min-w-5 text-[#333333]" />

          {options[optionKey].color && (
            <div
              className="size-3 min-h-3 min-w-3 rounded-full"
              style={{ backgroundColor: options[optionKey].color }}
            />
          )}
          <div>
            <p title={options[optionKey].name} className="line-clamp-1 flex-1 break-all text-left text-sm">
              {options[optionKey].name}
            </p>
            {options[optionKey].description && (
              <p className=" line-clamp-1 flex-1 break-all text-left text-xs text-neutral-500">
                {options[optionKey].description}
              </p>
            )}
          </div>
        </div>

        <div className="flex gap-1">
          <OptionAction
            onClick={() => {
              openModal('createWorkflowStatus', {
                workflowId: workflowId,
                statusId: options[optionKey].id,
                name: options[optionKey].name,
                description: options[optionKey].description,
                color: options[optionKey].color,
                action: 'updateWorkflowStatus',
              });
            }}
            icon={Pencil}
            type="button"
          />
          <OptionAction
            onClick={() => handleRemoveOption(optionKey)}
            icon={Trash}
            variant="destructive"
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

type WorkflowFieldData = {
  name?: string;
  description?: string;
  id: string;
  statuses?: Record<string, { id: string; name: string; color: string; description?: string }>;
};

export const WorkflowFields: FC<{ workflowId: string }> = ({ workflowId }) => {
  const queryClient = useQueryClient();
  const openModal = useOpenModal();

  const { toast } = useToast();
  const { workflow: fieldData, workflowIsFetching } = useWorkflow(workflowId) as {
    workflow: WorkflowFieldData;
    workflowIsFetching: boolean;
  };

  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>(['approval-workflow']);
  const [options, setOptions] = useState<
    Record<string, { id: string; name: string; color: string; description?: string }>
  >(
    fieldData?.statuses
      ? Object.fromEntries(
          Object.entries(fieldData.statuses).map(([key, value]) => [
            key,
            { id: value.id, name: value.name, color: value.color, description: value.description },
          ]),
        )
      : {},
  );

  // Create a dynamic form schema based on the fieldData
  const createFormSchema = (data: WorkflowFieldData) => {
    const schema: Record<string, z.ZodType<any>> = {};

    Object.keys(data).forEach((key) => {
      switch (key) {
        case 'name':
          schema[key] = z.string().min(1, { message: `${capitalize(key)} field is required.` });
          break;
        case 'description':
          schema[key] = z.string({ required_error: 'The description field must be a string.' }).min(1, {
            message: 'Description field is required.',
          });
          break;
        // case 'statuses':
        //   schema[key] = z
        //     .union([
        //       z.array(z.any()),
        //       z.object({
        //         choices: z.record(z.string()),
        //       }),
        //     ])
        //     .optional();
        //   break;
        default:
          break;
      }
    });

    return z.object(schema);
  };

  const formSchema = fieldData ? createFormSchema(fieldData) : z.object({});

  // Dynamic default values based on the fieldData
  const createDefaultValues = (data: WorkflowFieldData) => {
    const defaultValues: Partial<Record<keyof WorkflowFieldData, any>> = {};

    if (data) {
      Object.keys(data).forEach((key) => {
        defaultValues[key as keyof WorkflowFieldData] = data[key as keyof WorkflowFieldData];
      });

      return defaultValues;
    }
  };

  const form = useForm({
    resolver: zodResolver(fieldData && createFormSchema(fieldData)),
    defaultValues: fieldData && createDefaultValues(fieldData),
  });

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  const handleRemoveOption = (key: string) => {
    openModal('deleteWorkflowStatusAlertDialog', {
      workflowId: workflowId,
      statusId: options[key].id,
      name: options[key].name,
      color: options[key].color,
    });
  };

  const handleAddNewOption = () => {
    openModal('createWorkflowStatusInspector', {
      workflowId: workflowId,
      name: '',
      description: '',
      color: '#1456ff',
      action: 'createWorkflowStatus',
    });
  };

  // Reset form values when fieldData changes
  useEffect(() => {
    form.reset(createDefaultValues(fieldData));
    setOptions(
      fieldData?.statuses
        ? Object.fromEntries(
            Object.entries(fieldData.statuses).map(([key, value]) => [
              key,
              { id: value.id, name: value.name, color: value.color, description: value.description },
            ]),
          )
        : {},
    );
  }, [fieldData, form.reset]);

  const updateWorkflowWithAuth = useAuthenticatedQueryFn(updateWorkflow);

  const updateWorkflowMutation = useMutation({
    mutationFn: updateWorkflowWithAuth,
    onSuccess: async () => {
      toast({
        title: 'Workflow Saved',
        description: 'Workflow fields have been saved successfully.',
      });

      await queryClient.invalidateQueries({ queryKey: ['workflowList'] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      errorLog(err);
    },
  });

  function onSubmit(values: z.infer<ReturnType<typeof createFormSchema>>) {
    // Remove options that are empty or contain only whitespace
    const cleanedOptions = Object.fromEntries(
      Object.entries(options).filter(([_, option]) => option.name && option.name.trim() !== ''),
    );

    // Collect all kebab-cased keys to check for duplicates
    const keys = Object.values(cleanedOptions).map((option) => kebabCase(option.name));

    // Check for duplicate keys
    const duplicates = keys.filter((key, index) => keys.indexOf(key) !== index);
    if (duplicates.length > 0) {
      toast({
        title: 'Duplicate Statuses Found',
        description: 'Each status must be unique. Please ensure there are no duplicate entries.',
      });
      return; // Prevent form submission if duplicates exist
    }

    const formattedOptions = Object.fromEntries(
      Object.entries(cleanedOptions).map(([key, option]) => [kebabCase(option.name), option]),
    );

    updateWorkflowMutation.mutate({
      id: fieldData.id,
      body: {
        ...values,
        statuses: formattedOptions,
      },
    });
  }

  const isLoading = updateWorkflowMutation.status === 'pending';

  return (
    <Accordion
      onValueChange={(accordion) => {
        setCurrentAccordionItems(accordion);
      }}
      value={currentAccordionItems}
      type="multiple"
      className="w-full"
      defaultValue={['approval-workflow']}
    >
      <AccordionItem className="border-0" value={'approval-workflow'}>
        <AccordionTrigger className="h-[48px] border-y border-b-neutral-300 border-t-neutral-100 bg-neutral-200 p-3 hover:no-underline @[18rem]/inspector:py-3 dark:border-b-black dark:border-t-[#2D2D2D] dark:bg-[#222222]">
          Approval Workflow
        </AccordionTrigger>
        <AccordionContent className="flex flex-col bg-neutral-200 p-3 dark:bg-[#1C1C1C]">
          {workflowIsFetching ? (
            <div className="flex flex-col space-y-6">
              {Array.from(Array(3)).map((_, index) => (
                <>
                  <div key={`skeleton-${index}`} className="flex flex-col space-y-3">
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/5': index === 0 })} />
                    <Skeleton className="h-8 w-full" />
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/3': index === 1 })} />
                  </div>
                  {index !== 2 && <Separator className="block" />}
                </>
              ))}
            </div>
          ) : (
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="relative flex flex-col space-y-6 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
              >
                {Object.keys(fieldData).map((key) => {
                  const validation = (formSchema.shape as Record<string, z.ZodType<any>>)[key];

                  switch (key) {
                    case 'name':
                      return renderField(
                        form,
                        {
                          'name': 'Workflow Name',
                          'type': 'string',
                          'slug': key,
                          'disabled': workflowIsFetching || isLoading,
                          validation,
                          'data-cy': 'workflow-name-field',
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    case 'description':
                      return renderField(
                        form,
                        {
                          'name': 'Workflow Description',
                          'type': 'text',
                          'slug': key,
                          'disabled': workflowIsFetching || isLoading,
                          validation,
                          'data-cy': 'workflow-description-field',
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    case 'statuses':
                      return (
                        <div key={key} className="flex flex-col gap-4 @[30rem]/inspector:gap-2">
                          <div
                            className={cn(
                              'flex flex-col space-y-2 @[30rem]/inspector:flex-row @[30rem]/inspector:gap-x-3',
                            )}
                          >
                            <div className="flex items-start gap-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end">
                              <FormLabel
                                className={cn(
                                  'mx-2 leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:mx-0 @[30rem]/inspector:mr-1.5 @[30rem]/inspector:text-right',
                                )}
                              >
                                Statuses
                              </FormLabel>
                            </div>
                            <div className="relative @[30rem]/inspector:!mt-0 @[30rem]/inspector:w-1/2">
                              <Options
                                handleAddNewOption={handleAddNewOption}
                                disabled={workflowIsFetching || isLoading}
                              >
                                {Object.keys(options).map((optionKey) => (
                                  <OptionItem
                                    key={optionKey}
                                    optionKey={optionKey}
                                    options={options}
                                    handleRemoveOption={handleRemoveOption}
                                    workflowId={workflowId}
                                  />
                                ))}
                              </Options>
                              <Separator className="mt-6 block @[24rem]/inspector:hidden" />
                            </div>
                          </div>
                        </div>
                      );
                    default:
                      return null;
                  }
                })}

                <Button
                  disabled={workflowIsFetching || isLoading}
                  type="submit"
                  variant="default"
                  className="w-full justify-center @[21rem]/inspector:w-auto"
                  data-cy="workflow-save-button"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 size-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="mr-2 size-4" />
                      Save
                    </>
                  )}
                </Button>
              </form>
            </FormProvider>
          )}
        </AccordionContent>
      </AccordionItem>
      <DeleteWorkflowStatusAlertDialog />
      <CreateWorkflowStatusDialog modalId="createWorkflowStatusInspector" statuses={options} setStatuses={setOptions} />
    </Accordion>
  );
};
