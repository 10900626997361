import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { useDropzone } from 'react-dropzone';
import { Button } from '@/components/ui/button';
import { buttonVariants } from '@/components/ui/button';
import { useDialog } from '@/context/DialogContext';
import { useAsset } from '@/hooks/data/assets/useAsset';
import { useTree } from '@/hooks/data/tree/useTree';
import { AssetList } from '@/components/upload-dialog/asset-list';
import { UploadStatus } from '@/components/upload-dialog/upload-status';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
  DialogDescription,
} from '@/components/ui/dialog';

export type UploadStatus = 'ready' | 'uploading' | 'success' | 'error' | 'no-files' | 'no-folder' | 'failed';
export type Files = (Blob | File) & {
  preview: string;
};

export const AssetUploader = () => {
  const [files, setFiles] = useState<Array<Files>>([]);
  const [fileNames, setFileNames] = useState<Array<string>>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('ready');

  const { openDialogId, setOpenDialogId, dialogDefaultValue } = useDialog();
  const { createAssetVersion, createAsset } = useAsset();
  const { selectedFolder } = useTree();

  const isUploadAssetVersion = openDialogId === 'uploadAssetVersion';

  const { getRootProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      //Images
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg', '.pjpeg'],
      'image/gif': ['.gif'],
      'image/tiff': ['.tiff', '.tif'],
      //Documents
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
      'text/xml': ['.xml'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/rtf': ['.rtf'],
      'application/xml': ['.xml'],
      'application/zip': ['.zip'],
      'application/gzip': ['.gz'],
      //Videos
      'video/mp4': ['.mp4'],
    },
    multiple: !isUploadAssetVersion,
    maxFiles: isUploadAssetVersion ? 1 : undefined,
    onDrop: (acceptedFiles) => {
      setFileNames(acceptedFiles.map((file) => file.name));
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const removeFile = (index: number) => {
    setFiles((previousFiles) => previousFiles.filter((_, i: number) => i !== index));
    setFileNames((previousFileNames) => previousFileNames.filter((_, i: number) => i !== index));
  };

  const setFileName = (index: number, newFileName: string) => {
    setFileNames((previousFileNames) =>
      previousFileNames.map((oldFileName, i: number) => (i === index ? newFileName : oldFileName)),
    );
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setUploadStatus('no-files');
      return;
    } else if (!selectedFolder && !isUploadAssetVersion) {
      setUploadStatus('no-folder');
      return;
    }

    setUploadStatus('uploading');
    setUploadProgress(0);

    try {
      for (const file of files) {
        if (isUploadAssetVersion && dialogDefaultValue) {
          await createAssetVersion({
            folderId: '',
            file,
            onProgress: setUploadProgress,
            originalFileName: fileNames[files.indexOf(file)],
            assetId: dialogDefaultValue.id,
          });
        } else {
          if (selectedFolder) {
            await createAsset({
              folderId: selectedFolder,
              file,
              onProgress: setUploadProgress,
              originalFileName: fileNames[files.indexOf(file)],
            });
          }
        }
      }

      setUploadStatus('success');

      setTimeout(() => {
        setFiles([]);
        setUploadStatus('ready');
      }, 3000);
    } catch (err) {
      setUploadStatus('error');
      throw err;
    }
  };

  // Revoking data set by createObjectURL to avoid memory leaks, runs on component unmount
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Dialog
      open={openDialogId === 'uploadAsset' || isUploadAssetVersion}
      onOpenChange={() => {
        setFiles([]);
        setOpenDialogId(null);
      }}
    >
      <DialogContent className="!dark:bg-neutral-900">
        <DialogHeader>
          <DialogTitle className="flex gap-2">
            {isUploadAssetVersion ? 'Upload Asset Version' : 'Upload Assets'}
          </DialogTitle>
          <DialogDescription>
            {isUploadAssetVersion ? 'Upload a new version of the asset' : 'Upload new assets'}
          </DialogDescription>
        </DialogHeader>

        <div
          className={cn(
            'dropzone flex justify-center rounded-md border border-dashed border-neutral-200 px-6 pb-6 pt-5 dark:border-neutral-800',
            isFocused && 'border-neutral-500 bg-neutral-500/[.05]',
            isDragAccept && 'border-green-500 bg-green-500/[.05]',
            isDragReject && 'border-red-500 bg-red-500/[.05]',
          )}
          {...getRootProps()}
        >
          <div className="space-y-1 text-center">
            <svg
              className={cn(
                'mx-auto h-12 w-12 text-neutral-400',
                isDragReject && 'text-red-500',
                isFocused && 'text-neutral-500',
                isDragAccept && 'text-green-500',
              )}
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-neutral-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-neutral-500 focus-within:ring-offset-2 hover:text-neutral-500"
              >
                <p className="text-neutral-800 dark:text-neutral-200">
                  <span className="text-neutral-500">Click or drag and drop</span> to upload{' '}
                  {`${isUploadAssetVersion ? 'a new asset version' : 'files'}`}
                </p>
                <p className="text-xs text-neutral-500">PNG, JPG, GIF, TIFF up to 10MB</p>
              </label>
            </div>
          </div>
        </div>
        {files.length ? (
          <AssetList
            files={files}
            removeFile={removeFile}
            uploadStatus={uploadStatus}
            fileNames={fileNames}
            setFileName={setFileName}
          />
        ) : (
          ''
        )}
        <DialogFooter
          className={cn(
            'flex flex-col items-center gap-2',
            files.length && 'sm:justify-between', // AlertDialogFooter uses sm: class this one overrides it
          )}
        >
          {files.length ? <UploadStatus status={uploadStatus} uploadProgress={uploadProgress} /> : ''}
          <div className="flex w-full flex-col gap-2 sm:w-auto sm:flex-row">
            {uploadStatus !== 'uploading' && (
              <DialogClose
                className={cn(
                  buttonVariants({
                    variant: uploadStatus === 'success' ? 'default' : 'outline',
                  }),
                  uploadStatus === 'success' && 'hover:text-white',
                )}
              >
                {uploadStatus === 'success' ? 'Done' : 'Dismiss'}
              </DialogClose>
            )}

            {uploadStatus !== 'success' && (
              <Button
                onClick={handleUpload}
                className={cn(
                  buttonVariants({
                    variant: uploadStatus === 'uploading' ? 'destructive' : 'default',
                  }),
                )}
                disabled={files.length < 1}
              >
                {uploadStatus === 'uploading' ? 'Cancel upload' : 'Start upload'}
              </Button>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
