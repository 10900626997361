export enum EntityType {
  User = 'User',
  Invitation = 'Invitation',
  Tag = 'Tag',
  Workflow = 'Workflow',
  Metadata = 'Metadata',
  Variant = 'Variant',
  Asset = 'Asset',
  Share = 'Share',
}
