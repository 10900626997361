import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
} from '@/components/ui/alert-dialog';
import { useToast } from '@/components/ui/use-toast';
import { useDialog } from '@/context/DialogContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { deleteMetadata } from '@/services/metadata.service';
import { ToastAction } from '@/components/ui/toast';
import { error as errorLog } from '@/utilities/log';

interface MetadataDialogDefaultValue {
  id: string;
  name: string;
  onConfirm?: () => void;
}

export const DeleteMetadataAlertDialog = () => {
  const { toast } = useToast();
  const { openDialogId, setOpenDialogId, dialogDefaultValue } = useDialog();
  const metadataDefaultValue = dialogDefaultValue as MetadataDialogDefaultValue | null;

  const queryClient = useQueryClient();

  const deleteMetadataWithAuth = useAuthenticatedQueryFn(deleteMetadata);

  const deleteMetadataMutation = useMutation({
    mutationFn: deleteMetadataWithAuth,
    onSuccess: () => {
      toast({
        title: 'Metadata field deleted',
        description: `Metadata field has been deleted successfully.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['metadataList'] });

      if (metadataDefaultValue?.onConfirm) {
        metadataDefaultValue.onConfirm();
      }
    },
    onError: (err) => {
      errorLog('Action failed:', err);

      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  return (
    <AlertDialog open={openDialogId === 'deleteMetadataConfirmation'} onOpenChange={() => setOpenDialogId(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            {`Are you sure you want to delete metadata field `}
            <span className="font-bold">{metadataDefaultValue?.name}</span>
            {`?`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="!bg-red-500 !text-white transition-all duration-150 hover:!bg-red-600"
            onClick={() => {
              if (metadataDefaultValue && 'id' in metadataDefaultValue) {
                deleteMetadataMutation.mutate({
                  id: metadataDefaultValue?.id,
                });
              }
            }}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
