import { FC } from 'react';
import { AlertDialogAction } from '@/components/ui/alert-dialog';

export const DeleteNodeAlertAction: FC<{
  isRemove: boolean;
  removeAction: () => void;
  deleteAction: () => void;
}> = ({ isRemove, removeAction, deleteAction }) => {
  const actionText = isRemove ? 'Delete' : 'Restore';
  const clickFunction = isRemove ? removeAction : deleteAction;
  return (
    <AlertDialogAction
      className="!bg-red-500 !text-white transition-all duration-150 hover:!bg-red-600"
      onClick={() => {
        clickFunction();
      }}
    >
      {actionText}
    </AlertDialogAction>
  );
};
