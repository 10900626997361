import React from 'react';

import { Asset, Variants } from '@/types/asset';
import { FileVideo } from 'lucide-react';

export type VideoPlayerProps = {
  variants: NonNullable<Asset['variants']>;
};

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ variants }) => {
  const thumbnailUrl = variants[Variants.ThumbnailInternal]?.url;
  const videoUrl = variants[Variants.VideoPreviewInternal]?.url;

  return (
    <>
      {videoUrl ? (
        <video preload="none" className="w-full object-cover" controls poster={thumbnailUrl}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="relative flex aspect-square max-h-[calc(100vh-300px)] w-full items-center justify-center">
          <FileVideo strokeWidth={0.5} className="stroke-neutral-500 dark:stroke-neutral-700" size={200} />
        </div>
      )}
    </>
  );
};
