import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { folderSchema, CreateFolderParams, DeleteFolderParams, RenameFolderParams } from '@/types/folder';
import { getAuditsSchema } from '@/types/audit';

const getFolder = async (params?: string, token?: string) => {
  const response = await damRequest('GET', `${INTERNAL_V1}/folders/${params}`, token, {});
  return folderSchema.parse(response);
};

const createFolder = async (params?: CreateFolderParams, token?: string) => {
  const response = await damRequest('POST', `${INTERNAL_V1}/folders`, token, {
    body: params,
  });

  return folderSchema.parse(response);
};

const renameFolder = async (
  { id, name, description, slug }: RenameFolderParams & { id?: string | null },
  token?: string,
) => {
  const response = await damRequest('PATCH', `${INTERNAL_V1}/folders/${id}`, token, {
    body: { name, description, slug },
  });

  return folderSchema.parse(response);
};

const deleteFolder = async (params?: DeleteFolderParams, token?: string) => {
  const response = await damRequest('DELETE', `${INTERNAL_V1}/folders/${params?.id}`, token);
  return folderSchema.parse(response);
};

const unDeleteFolder = async (params?: DeleteFolderParams, token?: string) => {
  const response = await damRequest('POST', `${INTERNAL_V1}/folders/${params?.id}/undelete`, token, {});
  return folderSchema.parse(response);
};

const getFolderAuditLog = async (
  params?: { id?: string; pagination?: { offset: number; limit: number } },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? 20),
  }).toString();

  const response = await damRequest('GET', `${INTERNAL_V1}/folders/${params?.id}/audit?${query}`, token);
  return getAuditsSchema.parse(response);
};

export { getFolder, createFolder, deleteFolder, unDeleteFolder, renameFolder, getFolderAuditLog };
