import { Children, FC, forwardRef, PropsWithChildren, ReactNode } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { cn } from '@/lib/utils';

interface OptionsProps {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  emptyState?: ReactNode;
  handleAddNewOption?: () => void;
}

export const Options: FC<PropsWithChildren<OptionsProps>> = forwardRef<HTMLDivElement, PropsWithChildren<OptionsProps>>(
  ({ handleAddNewOption, className, disabled, isLoading, children, emptyState }, ref) => {
    return (
      <ScrollArea
        ref={ref}
        className={cn(
          'flex w-full flex-col rounded-sm border border-neutral-200 bg-white transition-[height] duration-150 data-[data-radix-scroll-area-viewport]:bg-black dark:border-neutral-800 dark:bg-neutral-950',
          Children.count(children) > 0 ? 'h-72' : 'h-auto',
          handleAddNewOption && 'pb-[36px]',
          (disabled || isLoading) && 'pointer-events-none opacity-50',
        )}
      >
        {children && Children.count(children) > 0 ? (
          <div className={cn('flex flex-col', className)}>{children}</div>
        ) : emptyState ? (
          emptyState
        ) : (
          <p className="text-balance px-4 py-12 text-center text-neutral-400">
            There are no options, click <Plus className="inline size-4" /> to add more options.
          </p>
        )}

        {/* Add a new empty option to the end of the list */}
        {handleAddNewOption && (
          <Button
            onClick={() => handleAddNewOption()}
            type="button"
            className="group/add-new-option absolute bottom-0 w-full justify-start rounded-none border border-x-neutral-300 border-b-neutral-300 border-t-neutral-200 bg-[#e9e9e9] px-3 shadow-none hover:bg-[#e9e9e9] dark:border-x-black dark:border-b-black dark:border-t-[#2D2D2D] dark:bg-[#232323] hover:dark:bg-[#232323]"
          >
            <Plus className="size-4 stroke-[#999999] duration-150 group-hover/add-new-option:stroke-neutral-700 dark:group-hover/add-new-option:stroke-neutral-100" />
          </Button>
        )}
      </ScrollArea>
    );
  },
);

Options.displayName = 'Options';
