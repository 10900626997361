import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { getDestinationsSchema, destinationArraySchema } from '@/types/destination';

export const getDestinationList = async (params?: string, token?: string) => {
  const response = await damRequest('GET', `${INTERNAL_V1}/destinations`, token);

  return getDestinationsSchema.parse(response);
};

export const setDestination = async (
  { destinationIds, itemId, type }: { destinationIds?: Array<string> | null; itemId?: string; type?: string },
  token?: string,
) => {
  const response = await damRequest('PUT', `${INTERNAL_V1}/${type}/${itemId}/destinations`, token, {
    body: { destinations: destinationIds },
  });

  return destinationArraySchema.parse(response);
};
