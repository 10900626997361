import { ReactNode, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { uniq, sortBy } from 'lodash';
import { usePathData, useTree } from '@/hooks/data/tree/useTree';
import { NodeType, TreeRecursiveChildrenNode } from '@/types/tree';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { Album } from '@/components/sidebar/tree/album';
import { Folder } from '@/components/sidebar/tree/folder';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';

type TreeProps = {
  className?: ReactNode;
};

export function Tree({ className }: TreeProps) {
  const { setOpenIds, openIds, tree } = useTree();

  const toggleNodeHandler = (clickedId: string) => {
    const isOpen = openIds.includes(clickedId);
    const updatedIds = uniq(isOpen ? openIds.filter((id) => id !== clickedId) : [...openIds, clickedId]);
    setOpenIds(updatedIds);
  };

  return (
    <ul className={cn('tree group/tree mt-px flex flex-col', className)} data-testid="tree">
      <Branch tree={tree} openIds={openIds} toggleNodeHandler={toggleNodeHandler} parentId={undefined} depth={0} />
    </ul>
  );
}

type BranchProps = {
  tree: Array<TreeRecursiveChildrenNode>;
  openIds: Array<string>;
  toggleNodeHandler: (id: string) => void;
  parentId?: string;
  depth: number;
};

export function Branch({ tree, openIds, toggleNodeHandler, parentId, depth }: BranchProps) {
  const sortedTree = sortBy(tree, (treeItem) => treeItem.node.name.toLowerCase());

  return sortedTree?.map((treeBranch) => {
    const { id, type } = treeBranch;
    switch (type) {
      case 'folder':
        return (
          <Folder
            key={id}
            treeBranch={treeBranch}
            openIds={openIds}
            toggleNodeHandler={toggleNodeHandler}
            parentId={parentId}
            depth={depth}
          />
        );
      case 'album':
        return <Album key={id} treeBranch={treeBranch} parentId={parentId} depth={depth} />;
      default:
        return null;
    }
  });
}
