import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { getTreeBranchNodeResponseSchema, getTreeRootNodeResponseSchema, moveNodesResponseSchema } from '@/types/tree';

export const getRootNodes = async (params?: null, token?: string) => {
  const response = await damRequest('GET', `${INTERNAL_V1}/tree`, token, {});
  return getTreeRootNodeResponseSchema.parse(response);
};

export const getNode = async (params: { nodeId: string; types?: string }, token?: string) => {
  const response = await damRequest('GET', `${INTERNAL_V1}/tree/${params.nodeId}`, token, {});
  return getTreeBranchNodeResponseSchema.parse(response);
};

export const getIdFromPath = async (params: { path: Array<string> }, token?: string) => {
  const response = await damRequest('POST', `${INTERNAL_V1}/tree/path`, token, { body: params });
  return response;
};

export const moveNodesQuery = async (
  params?: Array<{ parentFolderNodeId?: string; nodes: Array<string> }>,
  token?: string,
) => {
  const response = await damRequest('POST', `${INTERNAL_V1}/tree/move`, token, { body: { move: params } });
  return moveNodesResponseSchema.parse(response);
};
