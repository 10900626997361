import { FC, PropsWithChildren, useMemo } from 'react';
import { useCurrentModal, useCloseModal, useModalBack, useModalData as _useModalData } from '@/context/ModalContext';
import { Dialog, DialogPortal } from '@/components/ui/dialog';
import { AlertDialogPortal } from '@radix-ui/react-alert-dialog';
import { AlertDialog } from '@/components/ui/alert-dialog';

export const useModalData = _useModalData;

type ModalProps = {
  id: string | number;
  backOnClose?: boolean;
  variant?: 'dialog' | 'alert';
  onOpenChange?: (open: boolean) => void;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  id,
  backOnClose = false,
  variant = 'dialog',
  ...props
}) => {
  const currentModal = useCurrentModal();
  const closeModal = useCloseModal(id);
  const backModal = useModalBack();

  const memoizedId = useMemo(() => id, [id]);
  const isOpen = currentModal === memoizedId;

  const handleCloseModal = () => {
    if (backOnClose) {
      backModal();
    } else {
      closeModal();
    }
  };

  const Component = variant === 'dialog' ? Dialog : AlertDialog;
  const Portal = variant === 'dialog' ? DialogPortal : AlertDialogPortal;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          handleCloseModal();
        }
      }}
      {...props}
    >
      <Portal>{children}</Portal>
    </Component>
  );
};
