import { Eye, Image as ImageIcon, Video, Download, TrafficCone } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Asset } from '@/types/asset';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import { useToast } from '@/components/ui/use-toast';

import { Badge } from '@/components/ui/badge';
import { useMemo } from 'react';
import { ToastAction } from '@radix-ui/react-toast';
import { AssetThumbnail } from '../asset-thumbnail';
import { FileType } from '@/types/fileType';
import { VideoPlayer } from '../video-player';

const badgesClasses =
  'whitespace-nowrap rounded-full px-2 py-0 text-[10px] pointer-events-none dark:pointer-events-none';

export const VariantsAccordion = ({ asset, disabled }: { asset: Asset; disabled: boolean }) => {
  const { toast } = useToast();

  const variants = useMemo(() => asset?.variants, [asset]);

  const download = (downloadURL: string, name: string) => {
    if (!downloadURL) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Download url not provided',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
      return;
    }
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    try {
      link.click();
      toast({
        title: 'Variant downloading',
        description: 'The variant download has started',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    }
  };

  return (
    <AccordionItem value="variants" disabled={disabled}>
      <AccordionTrigger>Variants</AccordionTrigger>
      <AccordionContent>
        {!variants || (variants && Object.keys(variants).length === 0) ? (
          <AccordionContent className="py-8 text-center text-slate-600">
            <TrafficCone className="mx-auto mb-2" size={28} strokeWidth={1.5} />
            No Variants
          </AccordionContent>
        ) : (
          variants &&
          Object.keys(variants).map((key) => (
            <div key={key} className="mb-4 flex items-center gap-x-2">
              <div className="shrink-0">
                {variants[key].type === 'image' && <ImageIcon className="size-6 text-neutral-700" />}
                {variants[key].type === 'video' && <Video className="size-6 text-neutral-700" />}
              </div>

              <div className="flex grow flex-col overflow-hidden">
                <div className="truncate">
                  <strong>{variants[key].name}</strong> ({key})
                </div>
                <div className="flex flex-wrap items-center gap-1 overflow-hidden">
                  {Boolean(variants[key].automatic) && (
                    <Badge
                      className={badgesClasses + 'bg-[#333333] text-[#111111] dark:bg-[#333333] dark:text-[#b3b3b3]'}
                      variant="secondary"
                    >
                      Auto
                    </Badge>
                  )}
                  {Boolean(variants[key].public) && (
                    <Badge
                      className={badgesClasses + ' bg-[#4abeff] text-[#111111] dark:bg-[#4abeff] dark:text-[#111111]'}
                      variant="secondary"
                    >
                      Public
                    </Badge>
                  )}
                  {Boolean(variants[key].format) && (
                    <Badge
                      className={badgesClasses + ' bg-[#999999] text-[#111111] dark:bg-[#999999] dark:text-[#111111]'}
                      variant="secondary"
                    >
                      {variants[key].format?.toUpperCase()}
                    </Badge>
                  )}

                  <Badge
                    className={badgesClasses + 'bg-[#333333] text-[#111111] dark:bg-[#333333] dark:text-[#b3b3b3]'}
                    variant="secondary"
                  >
                    {variants[key].width} x {variants[key].width}
                  </Badge>
                  {Boolean(variants[key].watermark) && (
                    <Badge
                      className={badgesClasses + ' bg-[#ffac4a] text-[#111111] dark:bg-[#ffac4a] dark:text-[#111111]'}
                      variant="secondary"
                    >
                      Watermarked
                    </Badge>
                  )}
                </div>
              </div>

              <div className="flex shrink-0 gap-x-2">
                <Dialog>
                  <DialogTrigger asChild>
                    <Eye className="size-5 cursor-pointer text-neutral-700" />
                  </DialogTrigger>

                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>{variants[key].name}</DialogTitle>
                    </DialogHeader>
                    {variants[key].type === FileType.Video ? (
                      <VideoPlayer variants={variants} />
                    ) : (
                      <AssetThumbnail
                        name={variants[key].name!}
                        fileType={variants[key].type!}
                        thumbnail={variants[key].url}
                        iconSize={200}
                        iconStrokeWidth={0.5}
                        imageClassName="object-contain"
                        iconClassName="stroke-neutral-500 dark:stroke-neutral-400"
                        imageContainerClassName="relative aspect-square w-full"
                        iconContainerClassName="flex justify-center rounded-md border border-neutral-200 bg-white shadow-sm dark:border-neutral-800 dark:bg-neutral-900"
                      />
                    )}

                    <DialogFooter>
                      <DialogClose asChild>
                        <Button type="button" variant="secondary">
                          Close
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
                <Download
                  onClick={() => download(variants[key].url, variants[key].name!)}
                  className="size-5 cursor-pointer text-neutral-700"
                />
              </div>
            </div>
          ))
        )}
      </AccordionContent>
    </AccordionItem>
  );
};
