export type SortOption = {
  label: string;
  value: SortOrderValues;
};

export type SortState = {
  value: string;
  order: string;
};

export enum SortOrderOptions {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortOrderValues {
  CREATED_AT = 'created_at',
  MANUAL = 'album_asset.position',
}

export const sortOptions: Array<SortOption> = [
  {
    label: 'Creation date',
    value: SortOrderValues.CREATED_AT,
  },
  {
    label: 'Manual order',
    value: SortOrderValues.MANUAL,
  },
];
