import { AddAssetsResponse, RemoveAssetsResponse, ReorderAssetsParams } from '@/types/album';
import { removeAlbumAssets, addAlbumAssets, reorderAlbumAssets } from '@/services/album.service';
import { DefaultError, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';

export const useAlbumAssets = () => {
  const queryClient = useQueryClient();

  const addAlbumAssetsQueryFn = useAuthenticatedQueryFn(addAlbumAssets);
  const removeAlbumAssetsQueryFn = useAuthenticatedQueryFn(removeAlbumAssets);
  const reorderAlbumAssetsQueryFn = useAuthenticatedQueryFn(reorderAlbumAssets);

  const { mutateAsync: addAssets } = useMutation<
    AddAssetsResponse,
    DefaultError,
    Parameters<typeof addAlbumAssets>['0']
  >({
    mutationFn: (params) => addAlbumAssetsQueryFn(params),
  });

  const { mutateAsync: removeAssets } = useMutation<
    RemoveAssetsResponse,
    DefaultError,
    Parameters<typeof removeAlbumAssets>['0']
  >({
    mutationFn: (params) => removeAlbumAssetsQueryFn(params),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['assetData'] }),
  });

  const { mutateAsync: reorderAssets } = useMutation<
    ReorderAssetsParams,
    DefaultError,
    Parameters<typeof reorderAlbumAssets>['0']
  >({
    mutationFn: (params) => reorderAlbumAssetsQueryFn(params),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['assetData'] }),
  });

  return {
    addAssets,
    removeAssets,
    reorderAssets,
  };
};
