import { forwardRef, useMemo, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import type { ButtonProps } from '@/components/ui/button';
import { HexColorPicker } from 'react-colorful';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

interface ColorPickerProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const ColorPicker = forwardRef<HTMLInputElement, Omit<ButtonProps, 'value' | 'onChange' | 'onBlur'> & ColorPickerProps>(
  ({ disabled, value, onChange, onBlur, name, className, ...props }) => {
    const [open, setOpen] = useState(false);

    const parsedValue = useMemo(() => {
      return value || '#1456ff';
    }, [value]);

    return (
      <Popover onOpenChange={setOpen} open={open} modal>
        <PopoverTrigger asChild disabled={disabled} onBlur={onBlur}>
          <Button
            {...props}
            className={cn('block', className)}
            name={name}
            onClick={() => {
              setOpen(true);
            }}
            size="icon"
            style={{
              backgroundColor: parsedValue,
            }}
            variant="outline"
          >
            <div />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full">
          <HexColorPicker color={parsedValue} onChange={onChange} />
          <Input
            className="mt-2 bg-white focus-within:ring-offset-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950"
            maxLength={7}
            onChange={(e) => {
              onChange(e?.currentTarget?.value);
            }}
            value={parsedValue}
          />
        </PopoverContent>
      </Popover>
    );
  },
);

ColorPicker.displayName = 'ColorPicker';

export { ColorPicker };
