import { useCallback, CSSProperties } from 'react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { FolderLock, FolderOpen, FolderPlus, LucideFolder, Pen, Trash2Icon } from 'lucide-react';
import { dropStyle, hoverStyles, selectedStyles, styles } from '@/components/sidebar/sidebar';
import { useDraggable, useDroppable, useDndMonitor } from '@dnd-kit/core';
import { ChevronRightIcon, DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { NodeType, TreeRecursiveChildrenNode } from '@/types/tree';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { MoveNodeCommand } from '@/hooks/commands/folder/MoveNodeCommand';
import { SortOrderValues } from '@/types/sort';
import { DraggableItems } from '@/types/dragDrop';
import { ParsedUrlQuery } from 'querystring';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { useSearch } from '@/context/SearchContext';
import { useRouter } from 'next/router';
import { useDialog } from '@/context/DialogContext';
import { useToast } from '@/components/ui/use-toast';
import { useTree } from '@/hooks/data/tree/useTree';
import { Branch } from '@/components/sidebar/tree/tree';
import { sortBy } from 'lodash';
import { CSS } from '@dnd-kit/utilities';
import { cn } from '@/lib/utils';
import { useCurrentPage } from '@/hooks/useCurrentPage';

type FolderProps = {
  treeBranch: TreeRecursiveChildrenNode;
  openIds: Array<string>;
  toggleNodeHandler: (id: string) => void;
  parentId?: string;
  depth: number;
};

export function Folder({ treeBranch, openIds, toggleNodeHandler, parentId, depth }: FolderProps) {
  const { openModal } = useDialog();
  const { setUpdatedIds } = useTree();
  const { id, node, children = [], with_children: withChildren } = treeBranch;
  const { name, locked = false, id: folderId } = node;
  const { query, push } = useRouter();
  const { selectedNodes, setSelectedNodes, setCurrentSelectedType, selectedFolder, moveNodes } = useTree();
  const { deselectAllAssets } = useAssetSelection();
  const { apply } = useCommandContext();
  const { toast } = useToast();
  const { setSearchInsideFolder } = useSearch();
  const { browseRedirection } = useCurrentPage();

  const findNodePath = useTreeStore((state) => state.findNodePath);
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);
  const isOpen = openIds.includes(id);

  const sortedChildren = sortBy(children, 'node.name');

  const onSelectFolder = useCallback(() => {
    deselectAllAssets();
    setSelectedNodes([{ id, name }]);
    setCurrentSelectedType(NodeType.Folders);
    setSearchInsideFolder(false);

    const pathArray = findNodePath(id);
    setSelectedAlbum(undefined);
    setSelectedFolder(id);

    browseRedirection(pathArray);
  }, [
    folderId,
    id,
    push,
    query,
    setCurrentSelectedType,
    setSelectedNodes,
    deselectAllAssets,
    name,
    setSearchInsideFolder,
  ]);

  const { setNodeRef, isOver } = useDroppable({
    id: folderId,
    data: {
      type: DraggableItems.FOLDER,
      id: folderId,
      parentId: parentId,
      accepts: [DraggableItems.FOLDER, DraggableItems.ASSET, DraggableItems.ALBUM],
    },
  });

  const {
    attributes,
    listeners,
    setNodeRef: setDragRef,
    isDragging,
    transform,
  } = useDraggable({
    id,
    data: { type: DraggableItems.FOLDER, id, name, parentId },
  });

  useDndMonitor({
    onDragEnd(event) {
      const { active, over } = event;

      if (
        over?.id === folderId &&
        over.data.current?.accepts.includes(active.data.current?.type) &&
        over.data.current?.type === DraggableItems.FOLDER &&
        (active.data.current?.type === DraggableItems.ASSET || active.data.current?.type === DraggableItems.ALBUM)
      ) {
        let assetIds = [active.data?.current?.id];
        if (active.data?.current?.type === DraggableItems.ASSET && selectedNodes?.length > 1) {
          assetIds = selectedNodes.map((asset) => asset.id);
        }

        const moveNodeCommand = MoveNodeCommand(moveNodes, id, active.data?.current?.parentId, assetIds);
        apply(moveNodeCommand);
        if (active.data?.current?.type === DraggableItems.ASSET) {
          toast({
            title: 'Asset(s) moved',
            description: `${selectedNodes.length} asset(s) have been moved successfully.`,
          });
          setSelectedNodes([]);
        }

        setUpdatedIds([id]);
      }
    },
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    // transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger className="flex-1">
        <li
          className={cn('mt-px flex flex-col', isDragging && 'rounded-md border border-dashed border-separator-color')}
          title={name}
        >
          <div
            ref={setNodeRef}
            style={{ paddingLeft: `${depth * 15}px`, ...style }}
            className={cn(
              'flex w-full items-center rounded-md pr-1.5',
              styles,
              !isDragging && hoverStyles,
              selectedFolder === folderId && selectedStyles,
              isOver && dropStyle,
            )}
          >
            {withChildren && (
              <div onClick={() => toggleNodeHandler(id)} className="group/chevron mr-px cursor-pointer select-none p-1">
                <div className="size-4 rounded duration-150 group-hover/chevron:bg-black/10 dark:group-hover/chevron:bg-white/10">
                  <ChevronRightIcon
                    className={cn('size-4 p-0.5 duration-150', {
                      'rotate-90': isOpen,
                    })}
                    strokeWidth={2}
                  />
                </div>
              </div>
            )}
            <button
              className={cn('flex w-full items-center justify-start py-1 outline-none', {
                'ml-6': !withChildren,
              })}
              onClick={onSelectFolder}
              onContextMenu={onSelectFolder}
              ref={setDragRef}
              {...listeners}
              {...attributes}
            >
              {locked ? (
                <FolderLock
                  className="mr-2 size-4 min-w-4 stroke-neutral-500 dark:stroke-neutral-400"
                  strokeWidth={2}
                />
              ) : isOpen ? (
                <FolderOpen className="mr-2 size-4 min-w-4 stroke-violet-500" strokeWidth={2} />
              ) : (
                <LucideFolder className="mr-2 size-4 min-w-4 stroke-violet-500" strokeWidth={2} />
              )}
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left">{name}</span>
                {/* placeholder for items counter */}
                {/* <Badge
                  className={cn(
                    'ml-2 whitespace-nowrap rounded-full px-2 py-0 text-[11px]',
                    selectedFolder === folderId
                      ? 'bg-neutral-100 dark:bg-neutral-900'
                      : 'bg-neutral-200 dark:bg-neutral-800',
                  )}
                  variant="secondary"
                >
                  {items.count}
                </Badge> */}
              </div>
            </button>
          </div>
          {isOpen && (
            <ul className="relative flex flex-col">
              <div
                className="absolute inset-y-1 z-20 ml-3 block w-px bg-neutral-600/10 opacity-0 transition-opacity duration-300 group-hover/tree:opacity-100 dark:bg-neutral-200/10"
                style={{ left: `${depth * 15}px` }}
              />
              <Branch
                tree={sortedChildren}
                openIds={openIds}
                toggleNodeHandler={toggleNodeHandler}
                parentId={id}
                depth={depth + 1}
              />
            </ul>
          )}
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('uploadAsset');
            }
          }}
        >
          <DocumentArrowUpIcon className="mr-2 size-4" />
          New Upload
        </ContextMenuItem>

        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('editCreateFolderAlbum', 'createFolder', { id: selectedFolder });
            }
          }}
        >
          <FolderPlus className="mr-2 size-4" />
          New Folder
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('editCreateFolderAlbum', 'createAlbum', { id: selectedFolder });
            }
          }}
        >
          <FolderPlus className="mr-2 size-4" />
          New Album
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          disabled={locked}
          onClick={() => openModal('editCreateFolderAlbum', 'renameFolder', { id: folderId })}
        >
          <Pen className="mr-2 size-4" />
          Rename
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            openModal('deleteConfirmation');
          }}
          className="!text-red-600 focus:!bg-red-100/50 dark:focus:!bg-red-950/50"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
