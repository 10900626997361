import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getTrashQuery, emptyTrashQuery } from '@/services/trash.service';
import { TrashNodesResponse } from '@/types/trash';
import { useQuery, useMutation, DefaultError, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentPage } from '@/hooks/useCurrentPage';

export const useTrash = (page: number, resultsPerPage: number) => {
  const getTrashQueryFn = useAuthenticatedQueryFn(getTrashQuery);
  const emptyTrashQueryFn = useAuthenticatedQueryFn(emptyTrashQuery);
  const queryClient = useQueryClient();
  const pagination = { limit: Number(resultsPerPage), offset: (Number(page ?? 1) - 1) * resultsPerPage };
  const { isAuthenticated } = useAuth0();
  const { uiSection } = useCurrentPage();

  const {
    data: trash,
    isLoading,
    isPending,
    error,
    refetch: refetchTrash,
  } = useQuery({
    queryKey: ['trash', { ...pagination }],
    queryFn: () => getTrashQueryFn({ pagination }),
    enabled: isAuthenticated && uiSection === 'trash',
  });

  const { mutateAsync: emptyTrash } = useMutation<TrashNodesResponse, DefaultError>({
    mutationFn: emptyTrashQueryFn,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['trash'] });
    },
  });

  return {
    trash,
    isLoading,
    isPending,
    error,
    refetchTrash,
    emptyTrash,
  };
};
