import { cn } from '@/lib/utils';
import { FC, PropsWithChildren } from 'react';

export const Toolbar: FC<PropsWithChildren & { className?: string }> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'flex max-h-12 min-h-12 items-stretch justify-between border-b border-b-void-gap bg-ul-color',
        className,
      )}
    >
      {children}
    </div>
  );
};
