import { useState, FC } from 'react';
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
  MenubarRadioGroup,
  MenubarSub,
  MenubarSubTrigger,
  MenubarSubContent,
  MenubarCheckboxItem,
  MenubarRadioItem,
} from '@/components/ui/menubar';
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import {
  File,
  LayoutGrid,
  LayoutList,
  LogOut,
  Moon,
  Pencil,
  Settings,
  Sun,
  User,
  ZoomIn,
  ZoomOut,
  FolderPlusIcon,
  Contrast,
  Pen,
  Trash,
  RotateCcwIcon,
  Tags,
  Search as SearchIcon,
  Tag,
} from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useAuth0 } from '@auth0/auth0-react';
import { cn } from '@/lib/utils';
import { useRouter } from 'next/router';
import { useTheme } from 'next-themes';
import { Slider } from '@/components/ui/slider';
import { setLocalStorage, LOCAL_STORAGE_KEYS } from '@/utilities/localStorage';
import { GetAssets } from '@/types/asset';
import { useCommandContext } from '@/context/CommandContext';
import { useDialog } from '@/context/DialogContext';
import { PageSize, useUserSettings } from '@/context/UserSettingsContext';
import { Logo } from '@/components/logo';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { DeleteNodeAlertComponent } from '@/components/delete-dialogs/delete-node-alert-component';
import { KeyboardShortcuts } from '@/components/keyboard-shortcuts';
import { EditCreateFolderAlbumDialog } from './edit-create-folder-album-dialog';
import { AssetUploader } from './upload-dialog/asset-uploader';
import { useTree } from '@/hooks/data/tree/useTree';
import { EmptyTrashAlertDialog } from '@/components/empty-trash-alert-dialog';
import { RestoreTrashAlertDialog } from '@/components/restore-trash-alert-dialog';
import { useTrashSelection } from '@/context/TrashSelectionContext';
import { GetTrashResponse } from '@/types/trash';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { QuickSearch } from '@/components/quick-search';
import { useHitsPerPage, usePagination, useStats } from 'react-instantsearch';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useAlgolia } from '@/context/AlgoliaContext';
import { SearchWithDateFilter } from '@/components/search-with-date-filter';
import { useSortBy } from 'react-instantsearch';
import { useLogout } from '@/hooks/data/account/useAccount';
import { GalleryView } from '@/types';

type NavigationProps = {
  data?: GetAssets;
  trash?: GetTrashResponse;
  showAssetSizeSlider?: boolean;
  showAssetsPerPageMenu?: boolean;
  showGalleryLayoutOptions?: boolean;
};

export const Navigation: FC<NavigationProps> = ({
  data,
  trash,
  showAssetSizeSlider = false,
  showAssetsPerPageMenu = false,
  showGalleryLayoutOptions = false,
}) => {
  const [count, setCount] = useState(data?.pagination?.count ?? 0);

  const { isMobile } = useBreakpoint();
  const { user, logout } = useAuth0();
  const { setTheme, theme } = useTheme();
  const { push, query } = useRouter();
  const { page } = query;
  const { openModal, dialogMode, dialogDefaultValue } = useDialog();
  const {
    assetSize,
    setAssetsPerPage,
    galleryView,
    setGalleryView,
    pageSizes,
    showSidebar,
    setShowSidebar,
    showInspector,
    setShowInspector,
    setAssetSize,
  } = useUserSettings();
  const { selectedAssetIds } = useAssetSelection();
  const { selectedTrashIds } = useTrashSelection();
  const { selectedNodes, selectedAlbum, selectedFolder } = useTree();
  const { undo, redo } = useCommandContext();
  const { hitsPerPage } = useStats();
  const { nbHits } = usePagination();
  const { isHomePage, isSearchPage, isTrashPage, isTagsPage, isManagePage } = useCurrentPage();
  const { refine } = useHitsPerPage({
    items: pageSizes.map((pageSize) => ({ label: `${pageSize}`, value: pageSize, default: pageSize === 20 })),
  });
  const { algoliaIndexes } = useAlgolia();

  const isAlbum = Boolean(selectedAlbum);

  const { logoutUser } = useLogout();

  const sortByApi = useSortBy({
    items: [
      {
        label: 'Default',
        value: algoliaIndexes.default,
      },
      {
        label: 'Created asc',
        value: algoliaIndexes.created_asc,
      },
      {
        label: 'Created desc',
        value: algoliaIndexes.created_desc,
      },
      {
        label: 'Published asc',
        value: algoliaIndexes.published_asc,
      },
      {
        label: 'Published desc',
        value: algoliaIndexes.published_desc,
      },
    ],
  });

  return (
    <div className="absolute inset-x-0 top-0 z-20 px-3 py-2">
      <div className="flex flex-row items-center gap-3">
        {!isMobile && <Logo className="z-20 mx-3" />}
        <Menubar className="z-20">
          <MenubarMenu>
            <MenubarTrigger>
              {isMobile ? <File className="size-6" strokeWidth={1.5} /> : isTrashPage ? 'Trash' : 'File'}
            </MenubarTrigger>
            <MenubarContent>
              {!isTrashPage && (
                <>
                  {isHomePage && (
                    <>
                      <MenubarItem onClick={() => openModal('uploadAsset')} disabled={!selectedFolder}>
                        <DocumentArrowUpIcon className="mr-2 size-4" />
                        New Upload <MenubarShortcut>⌘T</MenubarShortcut>
                      </MenubarItem>
                      <MenubarSeparator />
                      <MenubarItem
                        onClick={() => openModal('editCreateFolderAlbum', 'createFolder')}
                        disabled={isTrashPage || isAlbum}
                      >
                        <FolderPlusIcon className="mr-2 size-4" />
                        New Folder
                      </MenubarItem>
                      <MenubarItem
                        onClick={() => {
                          if (selectedFolder) {
                            openModal('editCreateFolderAlbum', 'createAlbum', { id: selectedFolder });
                          }
                        }}
                        disabled={isTrashPage || isAlbum}
                      >
                        <FolderPlusIcon className="mr-2 size-4" />
                        New Album
                      </MenubarItem>
                    </>
                  )}
                  {isTagsPage ? (
                    <MenubarItem onClick={() => openModal('editCreateTag', 'createTag')}>
                      <Tag className="mr-2 size-4" />
                      New Tag
                    </MenubarItem>
                  ) : (
                    <>
                      <MenubarSeparator />
                      <MenubarItem
                        disabled={!selectedFolder && !selectedAlbum}
                        onClick={() => {
                          openModal('editCreateFolderAlbum', isAlbum ? 'renameAlbum' : 'renameFolder', {
                            id: isAlbum ? selectedAlbum! : selectedFolder!,
                          });
                        }}
                      >
                        <Pen className="mr-2 size-4" />
                        Rename
                      </MenubarItem>
                    </>
                  )}
                </>
              )}
              {isTrashPage && (
                <>
                  <MenubarItem
                    disabled={selectedTrashIds.length === 0}
                    onClick={() => openModal('restoreTrashConfirmation')}
                  >
                    <RotateCcwIcon className="mr-2 size-4" />
                    Restore item{selectedTrashIds.length === 0 ? '/s' : selectedTrashIds?.length > 1 ? 's' : ''}
                  </MenubarItem>
                  <MenubarSeparator />
                  <MenubarItem
                    onClick={() => openModal('emptyTrashConfirmation')}
                    className="text-red-600 focus:bg-red-100 focus:text-red-600"
                  >
                    <Trash className="mr-2 size-4" />
                    Empty trash
                  </MenubarItem>
                </>
              )}
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>{isMobile ? <Pencil className="size-6" strokeWidth={1.5} /> : 'Edit'}</MenubarTrigger>
            <MenubarContent>
              <MenubarItem onClick={undo}>
                Undo <MenubarShortcut>⌘Z</MenubarShortcut>
              </MenubarItem>
              <MenubarItem onClick={redo}>
                Redo <MenubarShortcut>⇧⌘Z</MenubarShortcut>
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem disabled={selectedAssetIds?.length < 1}>Cut</MenubarItem>
              <MenubarItem disabled={selectedAssetIds?.length < 1}>Copy</MenubarItem>
              <MenubarItem disabled={selectedAssetIds?.length < 1}>Paste</MenubarItem>
              <MenubarItem
                disabled={selectedAssetIds?.length < 1 && selectedNodes?.length < 1}
                onClick={() => {
                  openModal('deleteConfirmation');
                }}
              >
                {selectedAssetIds?.length > 0 && isAlbum ? 'Remove' : 'Delete'}
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>
              {isMobile ? (
                galleryView === GalleryView.LIST ? (
                  <LayoutList className="size-6" strokeWidth={1.25} />
                ) : (
                  <LayoutGrid className="size-6" strokeWidth={1.25} />
                )
              ) : (
                'View'
              )}
            </MenubarTrigger>
            <MenubarContent>
              {showGalleryLayoutOptions && (
                <>
                  <MenubarRadioGroup value={galleryView}>
                    <MenubarRadioItem value={GalleryView.LIST} onClick={() => setGalleryView(GalleryView.LIST)}>
                      List <MenubarShortcut>⇧⌥L</MenubarShortcut>
                    </MenubarRadioItem>
                    <MenubarRadioItem value={GalleryView.TILE} onClick={() => setGalleryView(GalleryView.TILE)}>
                      Tile <MenubarShortcut>⇧⌥T</MenubarShortcut>
                    </MenubarRadioItem>
                  </MenubarRadioGroup>
                  <MenubarSeparator />
                </>
              )}
              {showAssetsPerPageMenu && (
                <>
                  <MenubarSub>
                    <MenubarSubTrigger inset>Assets per page</MenubarSubTrigger>
                    <MenubarSubContent>
                      <MenubarRadioGroup
                        value={
                          isSearchPage
                            ? String(hitsPerPage)
                            : isTrashPage
                            ? trash?.pagination?.limit && trash?.pagination.limit !== -1
                              ? `${trash?.pagination.limit}`
                              : '10'
                            : data?.pagination?.limit && data?.pagination.limit !== -1
                            ? `${data?.pagination.limit}`
                            : '10'
                        }
                      >
                        {pageSizes.map((pageSize: PageSize) => (
                          <MenubarRadioItem
                            key={pageSize}
                            value={`${pageSize}`}
                            onClick={() => {
                              if (!isSearchPage) {
                                void push({
                                  query: {
                                    ...query,
                                    page: (Number(page) - 1) * pageSize > count ? 1 : Number(page),
                                    results: Number(pageSize),
                                  },
                                });

                                setAssetsPerPage(pageSize);
                              } else {
                                refine(pageSize);
                              }
                            }}
                          >
                            {pageSize}
                          </MenubarRadioItem>
                        ))}
                      </MenubarRadioGroup>
                    </MenubarSubContent>
                  </MenubarSub>
                  <MenubarSeparator />
                </>
              )}
              <MenubarCheckboxItem checked={showSidebar} onClick={() => setShowSidebar((prev: boolean) => !prev)}>
                Show Sidebar <MenubarShortcut>⌘&#91;</MenubarShortcut>
              </MenubarCheckboxItem>
              <MenubarCheckboxItem checked={showInspector} onClick={() => setShowInspector((prev: boolean) => !prev)}>
                Show Inspector <MenubarShortcut>⌘&#93;</MenubarShortcut>
              </MenubarCheckboxItem>
              <MenubarSeparator />
              {!isMobile && galleryView === GalleryView.TILE && showAssetSizeSlider && (
                <>
                  <MenubarItem onClick={(e) => e.preventDefault()}>
                    <div className="flex w-full flex-col">
                      <div className="mx-auto mb-2">Asset size</div>
                      <div className="flex w-full flex-row gap-4">
                        <ZoomOut
                          className="size-6"
                          onClick={() =>
                            setAssetSize((prevSize) => {
                              const newValue = Math.max(prevSize[0] - 20, 0);
                              setAssetSize([newValue]);

                              return [newValue];
                            })
                          }
                        />
                        <Slider
                          defaultValue={assetSize}
                          value={assetSize}
                          max={100}
                          step={20}
                          onValueChange={(value) => {
                            setLocalStorage(LOCAL_STORAGE_KEYS.assetSize, value[0].toString());
                            setAssetSize(value);
                          }}
                        />
                        <ZoomIn
                          className="size-6"
                          onClick={() =>
                            setAssetSize((prevSize) => {
                              const newValue = Math.min(prevSize[0] + 20, 100);
                              setAssetSize([newValue]);

                              return [newValue];
                            })
                          }
                        />
                      </div>
                    </div>
                  </MenubarItem>
                  <MenubarSeparator />
                </>
              )}
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>
              {isMobile ? <Settings className="size-6" strokeWidth={1.5} /> : 'Configuration'}
            </MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onClick={() => {
                  void push('/tags');
                  setShowInspector(false);
                }}
              >
                <Tags className="mr-2 size-4" />
                Tags
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger
              className="cursor-pointer"
              onClick={() => {
                void push('/manage/tags');
              }}
            >
              Manage
            </MenubarTrigger>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>{isMobile ? <User className="size-6" strokeWidth={1.5} /> : 'User'}</MenubarTrigger>
            <MenubarContent>
              <div className="flex items-center gap-2.5 p-2">
                <Avatar className="!size-9">
                  <AvatarImage src={user?.picture} />
                  <AvatarFallback>
                    {user?.nickname
                      ?.split('.')
                      .map((name: string) => name[0].toUpperCase())
                      .join('')}
                  </AvatarFallback>
                </Avatar>
                <div>
                  <p className="">
                    {user?.nickname
                      ?.split('.')
                      .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))
                      .join(' ')}
                  </p>
                  <p className="text-xs text-neutral-500">{user?.email}</p>
                </div>
              </div>
              <MenubarSeparator />
              <MenubarSub>
                <MenubarSubTrigger>
                  {theme === 'light' ? (
                    <Sun className="mr-2 size-4" />
                  ) : theme === 'light' ? (
                    <Moon className="mr-2 size-4" />
                  ) : (
                    <Contrast className="mr-2 size-4" />
                  )}
                  Theme
                </MenubarSubTrigger>
                <MenubarSubContent>
                  <MenubarItem disabled={theme === 'light'} onClick={() => setTheme('light')}>
                    <Sun className="mr-2 size-4" />
                    Light
                  </MenubarItem>
                  <MenubarItem disabled={theme === 'dark'} onClick={() => setTheme('dark')}>
                    <Moon className="mr-2 size-4" />
                    Dark
                  </MenubarItem>
                  <MenubarItem disabled={theme === 'system'} onClick={() => setTheme('system')}>
                    <Contrast className="mr-2 size-4" />
                    System
                  </MenubarItem>
                </MenubarSubContent>
              </MenubarSub>
              <MenubarSeparator />
              <MenubarItem
                onClick={() => {
                  void logout({ logoutParams: { returnTo: window.location.origin } });
                  void logoutUser();
                }}
              >
                <LogOut className="mr-2 size-4" />
                <span>Log out</span>
                <MenubarShortcut>⇧⌘Q</MenubarShortcut>
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
          {isMobile && (
            <MenubarMenu>
              <MenubarTrigger disabled>
                <SearchIcon className="size-6 opacity-25" strokeWidth={1.5} />
              </MenubarTrigger>
            </MenubarMenu>
          )}
        </Menubar>
        {!isMobile && <QuickSearch />}
      </div>
      {isSearchPage && (
        <div className="relative z-50 mt-4 flex flex-row items-center justify-center gap-5">
          <div className={cn('text-lg text-neutral-400', !isSearchPage && 'mb-5')}>
            Showing <span className="font-semibold text-black dark:text-white">{nbHits}</span> results for{' '}
            <span className="font-semibold text-black dark:text-white">&quot;{query?.query}&quot;</span>
          </div>
          <div className="flex gap-2">
            <Select
              onValueChange={(e) => sortByApi.refine(e)}
              defaultValue={algoliaIndexes[sortByApi.currentRefinement]}
            >
              <SelectTrigger className="w-[150px]">
                <SelectValue placeholder={'Sort by'} />
              </SelectTrigger>
              <SelectContent>
                {sortByApi.options.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <SearchWithDateFilter />
          </div>
        </div>
      )}

      <DeleteNodeAlertComponent count={data?.pagination?.count} />
      <EmptyTrashAlertDialog />
      <RestoreTrashAlertDialog />
      <EditCreateFolderAlbumDialog mode={dialogMode} id={dialogDefaultValue?.id} />
      <KeyboardShortcuts />
      <AssetUploader />

      {!isManagePage && (
        <>
          <div
            className={cn(
              'pointer-events-none absolute inset-0 -bottom-10 z-10',
              isSearchPage &&
                '[background-image:linear-gradient(to_bottom,white_50%,transparent_100%)] dark:[background-image:linear-gradient(to_bottom,black_50%,transparent_100%)]',
            )}
          />
          <div
            className={cn(
              'pointer-events-none absolute inset-0 -bottom-5 z-10',
              isSearchPage && 'backdrop-blur-[5px] [mask-image:linear-gradient(to_bottom,white_70%,transparent_100%)]',
            )}
          />
        </>
      )}
    </div>
  );
};
