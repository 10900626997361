import { MouseEventHandler, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { buttonVariants, Button } from '@/components/ui/button';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useDialog } from '@/context/DialogContext';
import { DialogContent, Dialog, DialogHeader, DialogTitle, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { error as errorLog, warn } from '@/utilities/log';
import { useToast } from '@/components/ui/use-toast';
import { ToastAction } from '@/components/ui/toast';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editShare } from '@/services/share.service';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { DatePicker } from '@/components/ui/datepicker';
import { ChevronRightIcon, X } from 'lucide-react';
import CreatableSelect from 'react-select/creatable';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

import { MultiSelect } from '@/components/inspector/metadata-accordion/metadata-multiselect';
import {
  controlStyles,
  placeholderStyles,
  selectInputStyles,
  valueContainerStyles,
  singleValueStyles,
  multiValueStyles,
  multiValueLabelStyles,
  multiValueRemoveStyles,
  indicatorsContainerStyles,
  clearIndicatorStyles,
  indicatorSeparatorStyles,
  dropdownIndicatorStyles,
  menuStyles,
  groupHeadingStyles,
  optionStyles,
  noOptionsMessageStyles,
} from '@/components/ui/react-select-styles';
import { useVariantList } from '@/hooks/variants/useVariant';
import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton } from '@/components/ui/skeleton';
import { useShare } from '@/hooks/shares/useShares';
import { format, parseISO, startOfDay } from 'date-fns';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { shareFormSchema } from '@/types/share';
import { PreviewVariant } from '@/types/variant';
import { Asset, Variants } from '@/types/asset';
import { AssetThumbnail } from '@/components/asset-thumbnail';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Album } from '@/types/album';
import { Folder } from '@/types/folder';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

export const EditShareDialog = ({
  shareType,
  thumbnail,
  shareItem,
}: {
  shareType: string;
  thumbnail: Asset;
  shareItem: Album | Folder | Asset;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { isAuthenticated } = useAuth0();
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const { closeModal, openDialogId, setOpenDialogId, dialogDefaultValue } = useDialog();

  const { variantList, variantListIsPending, variantListIsFetching } = useVariantList(
    {
      pagination: {
        offset: null,
        limit: null,
      },
      sort: {
        value: SortOrderValues.CREATED_AT,
        order: SortOrderOptions.ASC,
      },
      queryString: '',
    },
    { enabled: isAuthenticated && openDialogId === 'editShare' },
  );
  const { variants } = variantList;

  const { toast } = useToast();
  const [sharedWithInputValue, setSharedWithInputValue] = useState('');
  const [variantsValue, setVariantsValue] = useState<Array<string>>([]);
  const [variantsOptions, setVariantsOptions] = useState<Array<Option>>([]);

  const form = useForm<z.infer<typeof shareFormSchema>>({
    resolver: zodResolver(shareFormSchema),
    defaultValues: {
      sharedWith: null,
      expiresAt: null,
      variants: {
        preview: null,
        extra: [],
      },
    },
  });

  const resetForm = () => {
    form.reset({
      sharedWith: null,
      expiresAt: null,
      variants: {
        preview: null,
        extra: [],
      },
    });
    setVariantsValue([]);
    setVariantsOptions([]);
    setSharedWithInputValue('');
    setSelectedDate(null);
  };

  const defaultNodeId = dialogDefaultValue ? dialogDefaultValue.nodeId : null;
  const defaultShareId = dialogDefaultValue ? dialogDefaultValue.id : null;

  useEffect(() => {
    if (!variantListIsPending && !variantListIsFetching) {
      const options = variants.map((variant) => ({ label: variant.name, value: variant.id }));
      setVariantsOptions(options);
    }
  }, [variants, variantListIsPending, variantListIsFetching]);

  const { setValue } = form;

  const editShareWithAuth = useAuthenticatedQueryFn(editShare);
  const queryClient = useQueryClient();

  const handleVariantListOnChange = (value: ReadonlyArray<Option>) => {
    const newValues = value.map((option) => option.value);
    setVariantsValue(newValues);
    setValue('variants.extra', newValues);
  };

  const {
    share,
    shareIsPending: shareIsLoading,
    shareIsFetching: shareIsFetching,
  } = useShare(
    { nodeId: defaultNodeId!, shareId: defaultShareId! },
    {
      enabled: Boolean(defaultNodeId) && Boolean(defaultShareId),
      isAuthenticated,
    },
    form,
  );

  useEffect(() => {
    if (share?.id && openDialogId && !shareIsFetching) {
      let formattedDate = null;
      if (share.expiresAt) {
        const dateOnly = startOfDay(parseISO(share.expiresAt));
        formattedDate = format(dateOnly, 'yyyy-MM-dd');
      }
      setSelectedDate(formattedDate);
      setValue('expiresAt', formattedDate);
      setValue('sharedWith', []);
      if (share.previewVariant) {
        setValue('variants.preview', share.previewVariant.id);
        setIsExpanded(true);
      }
      if (share.extraVariants && share.extraVariants.length > 0) {
        const extraVariantsValues = share.extraVariants.map((s: PreviewVariant) => s.id);
        setValue('variants.extra', extraVariantsValues);
        setVariantsValue(extraVariantsValues);
        setIsExpanded(true);
      }
    }
  }, [share, openDialogId, shareIsFetching]);

  const editShareMutation = useMutation({
    mutationFn: editShareWithAuth,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['shareList'] });
      void queryClient.invalidateQueries({
        queryKey: ['getShare', dialogDefaultValue?.nodeId, dialogDefaultValue?.id],
      });

      toast({
        title: 'Share updated',
        description: `Share has been successfully updated.`,
      });

      closeModal();
      resetForm();
    },
    onError: (err) => {
      errorLog('Action failed:', err);

      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const onSubmit = (values: z.infer<typeof shareFormSchema>) => {
    const nodeId = dialogDefaultValue?.nodeId;

    if (nodeId) {
      try {
        if (dialogDefaultValue?.id) {
          editShareMutation.mutate({
            nodeId,
            shareId: dialogDefaultValue?.id,
            body: {
              sharedWith: [],
              expiresAt: values.expiresAt,
              variants: {
                ...(values.variants.preview && { preview: values.variants.preview }),
                ...(variantsValue && { extra: variantsValue }),
              },
            },
          });
        } else {
          warn('Invalid data for editing share');
        }
      } catch (err) {
        errorLog('Action failed:', err);

        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Something went wrong',
          action: <ToastAction altText="Close">Close</ToastAction>,
        });
      }
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You need to select an album or a folder to share it',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    }
  };

  return (
    <>
      <Dialog
        open={openDialogId === 'editShare'}
        onOpenChange={() => {
          setOpenDialogId(null);
          resetForm();
          setCalendarOpen(false);
          setIsExpanded(false);
        }}
      >
        <DialogContent className="px-0">
          <DialogHeader className="px-4">
            <DialogTitle>
              {`Edit shared ${shareType} `}
              {shareIsLoading || shareIsFetching ? (
                <Skeleton className="mb-[-2px] ml-1 inline-flex h-[18px] w-[100px] rounded" />
              ) : (
                share?.id
              )}
            </DialogTitle>
          </DialogHeader>

          <div className="flex gap-x-4 px-8 py-4">
            <AssetThumbnail
              thumbnail={thumbnail?.variants?.[Variants.ThumbnailInternal]?.url}
              name={thumbnail?.name}
              fileType={thumbnail?.type ?? 'image'}
              fill={false}
              width={thumbnail?.variants[Variants.ThumbnailInternal]?.width}
              height={thumbnail?.variants[Variants.ThumbnailInternal]?.height}
              imageClassName="h-full w-full object-cover"
              imageContainerClassName="h-[128px] w-[128px]"
              iconSize={128}
              iconStrokeWidth={0.5}
            />

            <div className="flex flex-col gap-y-2">
              <div className="font-bold text-neutral-100">{shareItem?.name}</div>
              <div className="text-xs font-bold text-neutral-100 dark:text-[#b3b3b3]">{shareItem?.description}</div>
            </div>
          </div>
          <ScrollArea className="max-h-[calc(100vh-15rem)] overflow-y-auto">
            <div className="px-4">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                  {share.sharedWith && (
                    <FormField
                      control={form.control}
                      name="sharedWith"
                      render={() => (
                        <FormItem>
                          <FormLabel>Share with</FormLabel>
                          <FormControl>
                            <div className="relative">
                              <CreatableSelect
                                isDisabled
                                components={components}
                                inputValue={sharedWithInputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                value={[{ label: share.sharedWith, value: share.sharedWith }]}
                                classNames={{
                                  control: ({ isFocused, isDisabled }) =>
                                    cn(
                                      isDisabled && controlStyles.disabled,
                                      isFocused ? controlStyles.focus : controlStyles.nonFocus,
                                      controlStyles.base,
                                    ),
                                  placeholder: () => placeholderStyles,
                                  input: () => selectInputStyles,
                                  valueContainer: () => valueContainerStyles,
                                  singleValue: () => singleValueStyles,
                                  multiValue: () => multiValueStyles,
                                  multiValueLabel: () => multiValueLabelStyles,
                                  multiValueRemove: () => multiValueRemoveStyles,
                                  indicatorsContainer: () => indicatorsContainerStyles,
                                  clearIndicator: () => clearIndicatorStyles,
                                  indicatorSeparator: () => indicatorSeparatorStyles,
                                  dropdownIndicator: () => dropdownIndicatorStyles,
                                  menu: () => menuStyles,
                                  groupHeading: () => groupHeadingStyles,
                                  option: ({ isFocused, isSelected, isDisabled }) =>
                                    cn(
                                      isFocused && optionStyles.focus,
                                      isSelected && optionStyles.selected,
                                      isDisabled && optionStyles.disabled,
                                      optionStyles.base,
                                    ),
                                  noOptionsMessage: () => noOptionsMessageStyles,
                                }}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  <FormField
                    control={form.control}
                    name="expiresAt"
                    render={() => (
                      <FormItem>
                        <FormLabel>Expiration</FormLabel>
                        <FormDescription>
                          The share link will be valid until the selected date (inclusive).
                        </FormDescription>
                        <FormControl>
                          <div className="relative">
                            <DatePicker
                              disabled={shareIsLoading || shareIsFetching}
                              open={calendarOpen}
                              className="focus:border-neutral-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 focus:duration-150 focus:hover:border-neutral-200 dark:border-neutral-800 dark:ring-neutral-500 dark:ring-offset-neutral-900 dark:hover:border-neutral-800"
                              setOpen={setCalendarOpen}
                              value={selectedDate}
                              onChange={(value) => {
                                setSelectedDate(value);
                                setValue('expiresAt', value);
                              }}
                              {...(selectedDate && {
                                appendIcon: (
                                  <Button
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      setSelectedDate('');
                                      setValue('expiresAt', null);
                                    }}
                                    variant="ghost"
                                    className="mr-0 px-0 !text-neutral-400 duration-150 hover:bg-transparent hover:!text-red-300 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-neutral-200 dark:hover:bg-transparent dark:hover:!text-red-800  dark:focus-visible:ring-neutral-900"
                                    aria-label="Clear field"
                                  >
                                    <X className="size-3" />
                                  </Button>
                                ),
                              })}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Collapsible
                    open={isExpanded}
                    onOpenChange={setIsExpanded}
                    className="!mt-8 border-t border-ui-bevel pb-2 pt-4"
                  >
                    <CollapsibleTrigger asChild>
                      <div className={cn('mb-8 flex w-full items-center')}>
                        <div className="flex-1">
                          <div className="flex w-full cursor-pointer items-center justify-start py-1 outline-none">
                            <div className="flex w-full justify-between text-sm">
                              <h2 className="text-lg font-semibold leading-none tracking-tight">Advanced options</h2>
                            </div>
                          </div>
                        </div>
                        <div className="mr-px cursor-pointer select-none p-1">
                          <ChevronRightIcon
                            className={cn('size-5.5 text-[#999999] transition duration-300', {
                              'rotate-90': isExpanded,
                            })}
                          />
                        </div>
                      </div>
                    </CollapsibleTrigger>
                    <CollapsibleContent className="space-y-3 last-of-type:mb-3">
                      <FormField
                        control={form.control}
                        name="variants.extra"
                        render={() => (
                          <FormItem>
                            <FormLabel>Downloadable Variants</FormLabel>
                            <FormDescription>
                              Select the variants that will be available for download using the share link.
                            </FormDescription>
                            <FormControl>
                              <div className="relative">
                                <MultiSelect
                                  menuPlacement="top"
                                  className="z-[10000]"
                                  disabled={
                                    variantListIsPending || variantListIsFetching || shareIsLoading || shareIsFetching
                                  }
                                  isLoading={
                                    variantListIsPending || variantListIsFetching || shareIsLoading || shareIsFetching
                                  }
                                  value={variantsValue}
                                  options={variantsOptions}
                                  onChange={handleVariantListOnChange}
                                  menuListStyle="h-48"
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="variants.preview"
                        render={() => (
                          <FormItem>
                            <FormLabel>Preview Variant</FormLabel>
                            <FormDescription>
                              Select the variants that will be used to display a preview of the asset in the shared
                              album.
                            </FormDescription>
                            <FormControl>
                              <div className="relative">
                                <Select
                                  disabled={
                                    variantListIsPending || variantListIsFetching || shareIsLoading || shareIsFetching
                                  }
                                  onValueChange={(value) => setValue('variants.preview', value)}
                                >
                                  <SelectTrigger className="focus:duration-0">
                                    <SelectValue
                                      placeholder={
                                        share.previewVariant ? share.previewVariant.name : 'Select an option'
                                      }
                                    />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value={''}>
                                      <div>No variant</div>
                                    </SelectItem>
                                    {variantsOptions.map(({ label, value }) => (
                                      <SelectItem key={value} value={value}>
                                        <div>{label}</div>
                                      </SelectItem>
                                    ))}
                                  </SelectContent>
                                </Select>
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CollapsibleContent>
                  </Collapsible>

                  <DialogFooter>
                    <DialogClose asChild className="mt-2 sm:mt-0">
                      <Button type="button" variant="secondary">
                        Dismiss
                      </Button>
                    </DialogClose>
                    <Button
                      disabled={editShareMutation.isPending || shareIsFetching}
                      type="submit"
                      className={cn(
                        buttonVariants({
                          variant: 'default',
                        }),
                      )}
                    >
                      Save
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
};
