import { ComponentPropsWithoutRef, FC } from 'react';
import { ArrowDownNarrowWide, ArrowUpNarrowWide } from 'lucide-react';
import { SortOrderOptions } from '@/types/sort';
import { Button } from '@/components/ui/button';

export const ToolbarSortOrderButton: FC<
  ComponentPropsWithoutRef<'button'> & { sort?: { order: SortOrderOptions } }
> = ({ sort, ...props }) => {
  const iconClasses = 'size-6 min-h-6 min-w-6 stroke-gray-icon-color duration-150 hover:stroke-gray-icon-hover-color';

  return (
    <Button
      variant="toolbar"
      size="toolbar"
      data-cy="toolbar-sort-order-button"
      title={sort?.order === SortOrderOptions.ASC ? 'Sort Descending' : 'Sort Ascending'}
      {...props}
    >
      {sort?.order === SortOrderOptions.ASC ? (
        <ArrowDownNarrowWide className={iconClasses} strokeWidth={2} />
      ) : (
        <ArrowUpNarrowWide className={iconClasses} strokeWidth={2} />
      )}
    </Button>
  );
};
