import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { assetSchema } from '@/types/asset';
import { statusFieldSchema, workflowFieldSchema, workflowListSchema } from '@/types/workflow';

const BASE = INTERNAL_V1 + '/workflows';

const createWorkflow = async (body: object, token?: string) => {
  const response = await damRequest('POST', `${BASE}`, token, { body });

  return workflowFieldSchema.parse(response);
};

const getWorkflow = async (id: string, token?: string) => {
  const response = await damRequest('GET', `${BASE}/${id}`, token);

  return workflowFieldSchema.parse(response);
};

const updateWorkflow = async ({ id, body }: { id: string; body: object }, token?: string) => {
  const response = await damRequest('PATCH', `${BASE}/${id}`, token, { body });

  return workflowFieldSchema.parse(response);
};

const getWorkflowList = async (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset ?? ''}&limit=${params.pagination?.limit ?? ''}&filterQuery=${
      params.queryString
    }&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return workflowListSchema.parse(response);
};

const deleteWorkflow = ({ id }: { id: string }, token?: string) => damRequest('DELETE', `${BASE}/${id}`, token);

const setWorkflow = async (
  { asset_id, workflows }: { asset_id?: string | null; workflows?: object | null },
  token?: string,
) => {
  const response = await damRequest('PATCH', `${INTERNAL_V1}/assets/${asset_id}`, token, {
    body: { workflows },
  });

  return assetSchema.parse(response);
};

const createWorkflowStatus = async ({ workflowId, body }: { workflowId: string; body: object }, token?: string) => {
  const response = await damRequest('POST', `${BASE}/${workflowId}/statuses`, token, { body });

  return statusFieldSchema.parse(response);
};

const deleteWorkflowStatus = ({ workflowId, statusId }: { workflowId: string; statusId: string }, token?: string) =>
  damRequest('DELETE', `${BASE}/${workflowId}/statuses/${statusId}`, token);

const updateWorkflowStatus = async (
  { workflowId, statusId, body }: { workflowId: string; statusId: string; body: object },
  token?: string,
) => {
  const response = await damRequest('PATCH', `${BASE}/${workflowId}/statuses/${statusId}`, token, { body });

  return statusFieldSchema.parse(response);
};

export {
  createWorkflow,
  getWorkflow,
  updateWorkflow,
  setWorkflow,
  getWorkflowList,
  deleteWorkflow,
  createWorkflowStatus,
  deleteWorkflowStatus,
  updateWorkflowStatus,
};
