import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { SelectIcon } from '@radix-ui/react-select';
import { CaretDownIcon } from '@radix-ui/react-icons';

export const FilterSelect = ({
  value,
  onValueChange,
  options,
  disabled,
}: {
  value: string;
  onValueChange: (value: string) => void;
  options: Array<{ label: string; value: string }>;
  disabled?: boolean;
}) => {
  return (
    <Select value={value} onValueChange={onValueChange} disabled={disabled}>
      <SelectTrigger
        noArrow
        className="flex h-full border-0 p-0 shadow-none focus:outline-none focus:ring-0 focus:ring-offset-0 focus:duration-0"
      >
        <div className="flex items-center self-stretch whitespace-nowrap bg-input-background px-3 text-xs">
          <SelectValue placeholder="Sort" />
        </div>
        <div className="flex h-full items-center border border-toolbar-bevel bg-ul-color">
          <SelectIcon asChild>
            <CaretDownIcon className="mx-2 size-8 min-h-8 min-w-8 text-gray-icon-color" />
          </SelectIcon>
        </div>
      </SelectTrigger>
      <SelectContent>
        {options.map(({ label, value: optionValue }) => (
          <SelectItem key={optionValue} value={optionValue}>
            {label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
