import { z } from 'zod';

export const previewVariantsFieldSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
});

export const variantFieldSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  fileType: z.string(),
  internal: z.boolean(),
  automatic: z.boolean(),
  public: z.boolean(),
  watermark: z.string().nullable().optional(),
  options: z
    .object({
      format: z.string().nullable().optional(),
      resize: z.string().nullable().optional(),
      width: z.number().nullable().optional(),
      height: z.number().nullable().optional(),
      quality: z.number().nullable().optional(),
    })
    .nullable()
    .optional(),
  createdAt: z.string(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  updatedAt: z.string(),
  updatedBy: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable()
    .optional(),
});

export const variantSchema = z.object({
  variants: z.array(variantFieldSchema),
  pagination: z.object({
    count: z.number(),
    limit: z.number(),
    offset: z.number(),
  }),
});

export type Variant = z.infer<typeof variantSchema>;
export type PreviewVariant = z.infer<typeof previewVariantsFieldSchema>;
