import { DateTime } from 'luxon';
import { z } from 'zod';

export const fieldTypeSchema = z.enum([
  'string',
  'text',
  'integer',
  'float',
  'boolean',
  'date',
  'datetime',
  'select',
  'multi_select',
  'linked_assets',
]);

export const metadataTypeOptions = [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Integer',
    value: 'integer',
  },
  {
    label: 'Float',
    value: 'float',
  },
  {
    label: 'Boolean',
    value: 'boolean',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Datetime',
    value: 'datetime',
  },
  {
    label: 'Select',
    value: 'select',
  },
  {
    label: 'Multi-Select',
    value: 'multi_select',
  },
  {
    label: 'Linked Assets',
    value: 'linked_assets',
  },
];

export type MetadataTypeMap = {
  string: string;
  text: string;
  integer: number;
  float: number;
  boolean: boolean;
  date: Date;
  datetime: DateTime;
  select: string;
  multi_select: Array<string>;
  linked_assets: Array<string>;
  options: object;
};

export type FieldType = z.infer<typeof fieldTypeSchema>;

export const metadataFieldSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  slug: z.string(),
  name: z.string(),
  description: z.string().optional(),
  type: fieldTypeSchema,
  searchable: z.boolean(),
  facet: z.boolean(),
  public: z.boolean(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  createdAt: z.string(),
  updatedBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  updatedAt: z.string(),
  options: z
    .union([
      z.array(z.any()),
      z.object({
        choices: z.record(z.string()),
      }),
    ])
    .optional(),
});

export const metadataSchema = z.object({
  metadata: z.array(metadataFieldSchema),
  pagination: z.object({
    count: z.number(),
    limit: z.number(),
    offset: z.number(),
  }),
});

export const publicMetadataFieldSchema = z.object({
  id: z.string(),
  slug: z.string(),
  name: z.string(),
  description: z.string().optional(),
  type: fieldTypeSchema,
  value: z.union([z.array(z.string()), z.string()]),
});

export type PublicMetadataField = z.infer<typeof publicMetadataFieldSchema>;
export type MetadataField = z.infer<typeof metadataFieldSchema>;
export type Metadata = z.infer<typeof metadataSchema>;

export const metadataMap = {
  string: 'String',
  text: 'Text',
  integer: 'Integer',
  float: 'Float',
  boolean: 'Boolean',
  date: 'Date',
  datetime: 'Datetime',
  select: 'Select',
  multi_select: 'Multi-Select',
  linked_assets: 'Linked Assets',
};
